import gql from 'graphql-tag';

// Mutation for uploading a document
export const ADD_DOCUMENT = gql`
  mutation AddProjectDocument($relatedDocumentId: String, $relatedModelName: String, $url: String, $description: String, $fileName: String, $type: String, $source: String, $mimeType: String, $category: String) {
    addDocument(relatedDocumentId: $relatedDocumentId, relatedModelName: $relatedModelName, url: $url, description: $description, fileName: $fileName, type: $type, source: $source, mimeType: $mimeType, category: $category)
  }
`;

// Mutation for updating a document
export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($id: String!, $fileName: String, $category: String) {
    updateDocument(id: $id, fileName: $fileName, category: $category)
  }
`;

// Mutation for deleting a document
export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($relatedDocumentId: String, $relatedModelName: String, $id: String) {
    deleteDocument(relatedDocumentId: $relatedDocumentId, relatedModelName: $relatedModelName, id: $id)
  }
`;

// Mutation for downloading a document
export const DOWNLOAD_DOCUMENT = gql`
  mutation DownloadDocument($documentId: String!) {
    downloadDocument(documentId: $documentId)
  }
`; 