import { useState } from 'react';
import { Badge, Group, Text, } from '@mantine/core';
import { Alert, Popconfirm, Button } from 'antd';
import client from '../../../../api/graphql/client';
import { DOWNLOAD_DOCUMENT } from './mutations';
import { useFileManager } from './FileManagerContext';

interface DocumentProps {
  document: {
    _id: string;
    fileName: string;
    type: string;
    source: string;
    category?: string;
  };
  onDelete: (id: string) => any;
  deletingDocIds?: string[];
  viewMode: 'grid' | 'list';
  relatedDocumentId: string;
  relatedModelName: string;
  refetchQueries: { query: any; variables: any }[];
}

const Document = ({
  document,
  viewMode,
  onDelete,
  deletingDocIds = [],
  relatedDocumentId,
  relatedModelName,
  refetchQueries,
}: DocumentProps) => {
  const [downloading, setDownloading] = useState(false);
  const { openEditModal } = useFileManager();
  
  const isDeleting = deletingDocIds.includes(document._id);

  const handleDownload = async (documentId: string) => {
    setDownloading(true);
    const response = await client.mutate({
      mutation: DOWNLOAD_DOCUMENT,
      variables: { documentId },
    });

    if (response.data.downloadDocument) {
      window.open(response.data.downloadDocument, '_blank');
    }
    setDownloading(false);
  };

  const handleEdit = () => {
    openEditModal(document);
  };

  const handleDelete = () => {
    onDelete(document._id);
  };

  const gridViewClasses = 'bg-gray-100';
  const listViewClasses =
    'flex items-center gap-3 p-3 rounded-md border border-gray-200 transition-colors bg-gray-100 w-full';

  const getTypeColor = (type: string) => {
    switch (type.toLowerCase()) {
      case 'pdf':
        return 'red';
      case 'image':
        return 'blue';
      default:
        return 'gray';
    }
  };

  const ActionsWrapper = () => (
    <div className="flex flex-start space-x-2">
      <Button
        color="blue"
        onClick={handleEdit}
      >
        Edit
      </Button>

      <Popconfirm
        title={
          <Alert
            message="Warning: Are you sure you want to delete this document?"
            type="warning"
          />
        }
        onConfirm={handleDelete}
        placement="left"
        okText="Yes"
        cancelText="No"
      >
        <Button
          loading={isDeleting}
          type="danger"
          icon="close"
        />
      </Popconfirm>


    </div>
  );

  return (
    <div className={viewMode === 'grid' ? gridViewClasses : listViewClasses}>
      {viewMode === 'grid' ? (
        // Grid View Layout
        <div className="space-y-3 p-4 cursor-pointer" onClick={(e) => {
          // Only handle download if not clicking on the actions wrapper
          if (!e.defaultPrevented) {
            handleDownload(document._id);
          }
        }}>
          <div className="flex items-center justify-between">
            <Group spacing="xs">
              <Badge color={getTypeColor(document.type)} variant="filled" size="sm">
                {document.type || 'File'}
              </Badge>
              {document.source === 'external' && (
                <Badge color="orange" variant="outline" size="sm">
                  External
                </Badge>
              )}
            </Group>
            <div onClick={(e) => e.preventDefault()}>
              <ActionsWrapper />
            </div>
          </div>
          <div>
            <Text size="sm" weight={500} className="mb-1">
              {downloading ? 'Opening...' : document.fileName}
            </Text>
            {/* {document.category && (
              <Text size="xs" color="dimmed">
                Category: {document.category}
              </Text>
            )} */}
          </div>
        </div>
      ) : (
        // List View Layout
        <>
          <div
            className="flex-1 flex items-center gap-3 cursor-pointer"
            onClick={() => handleDownload(document._id)}
          >
            <Group spacing="xs">
              <Badge color={getTypeColor(document.type)} variant="filled" size="sm">
                {document.type || 'File'}
              </Badge>
              {document.source === 'external' && (
                <Badge color="orange" variant="outline" size="sm">
                  External
                </Badge>
              )}
              {document.category && (
                <Badge color="grape" variant="light" size="sm">
                  {document.category}
                </Badge>
              )}
            </Group>
            <Text weight={500}>{downloading ? 'Opening...' : document.fileName}</Text>
          </div>
          <ActionsWrapper />
        </>
      )}
    </div>
  );
};

export default Document;
