import { Link } from 'react-router-dom';

const IntegrationsPage = () => (
  <div>
    <h1>Integrations</h1>
    <ul>
      <li><Link to="/super-admin/integrations/netsuite/syncs">NetSuite Syncs</Link></li>
      <li><Link to="/super-admin/integrations/netsuite/suiteql">NetSuite SuiteQL</Link></li>
    </ul>
  </div>
);

export default IntegrationsPage;
