import FileManager from '../../common/components/FileManager';

const ProjectDocuments = ({ project }) => {
  return (
    <div>
      <FileManager relatedDocumentId={project._id} relatedModelName="Project" />
    </div>
  );
};

export default ProjectDocuments;
