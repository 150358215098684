import React from 'react';
import classNames from 'classnames';

const Page = ({ children, header, full, padded = true }) => {
  return (
    <>
      {header && <div className="p-4 border-0 border-b border-solid border-gray-300 text-xl">{header}</div>}
      <div
        className={classNames('flex justify-center transition-all duration-1000 ease-in-out', {
          'p-1 md:pt-4': padded,
          'p-1 md:px-4': padded && full,
          'p-1 md:px-2': padded && !full
        })}
      >
        <div
          className={classNames('w-full transition-all duration-500 ease-in-out', {
            'max-w-7xl': !full,
            'max-w-full': full
          })}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Page;
