import { useState, FormEvent, KeyboardEvent } from 'react';
import { Textarea, Button, Paper, Text, Stack, Group, Loader } from '@mantine/core';
import { gql } from 'graphql-tag';
import client from '../../../api/graphql/client';

const NetsuiteIntegrationSuiteql = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    if (loading || !query.trim()) return;
    
    setLoading(true);
    setError(null);

    try {
      const { data } = await client.query({
        query: gql`
          query {
          admin {
            suiteql(query: "${query}")
          }
          }
        `,
      });

      const result = JSON.parse(data.admin.suiteql);
      setResults(result);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    // Check for Command+Enter (Mac) or Ctrl+Enter (Windows)
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Stack spacing="md" p="md">
      <Text size="xl" weight={700}>
        SuiteQL Query Interface
      </Text>

      <form onSubmit={handleSubmit}>
        <Stack spacing="md">
          <Textarea
            minRows={4}
            placeholder="Enter your SuiteQL query (Press ⌘+Enter to execute)"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            autosize
          />

          <Group>
            <Button
              type="submit"
              disabled={loading || !query.trim()}
              leftIcon={loading ? <Loader size="sm" /> : null}
            >
              {loading ? 'Executing...' : 'Execute Query'}
            </Button>
          </Group>
        </Stack>
      </form>

      {error && (
        <Paper p="md" withBorder color="red" radius="md">
          <Text color="red">{error}</Text>
        </Paper>
      )}

      {results && (
        <Paper p="md" withBorder radius="md">
          <Text size="lg" weight={600} mb="sm">Results</Text>
          <Paper p="md" withBorder bg="gray.0" style={{ maxHeight: '500px', overflow: 'auto' }}>
            <pre style={{ margin: 0 }}>
              {JSON.stringify(results, null, 2)}
            </pre>
          </Paper>
        </Paper>
      )}
    </Stack>
  );
};

export default NetsuiteIntegrationSuiteql;
