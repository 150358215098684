import React, { memo, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Dropzone } from '@mantine/dropzone';
import Document from './Document';
import { useFileManager } from './FileManagerContext';
import { filterAndSortDocuments, validateFileSize } from './utils';
import { GET_DOCUMENTS_BY_RELATED_ID } from './queries';
import { DELETE_DOCUMENT } from './mutations';
import { uploadFileToAzureBlob } from '../../../common';
import { ADD_DOCUMENT } from './mutations';
import { showError } from '../../../common/notifications';

interface DocumentType {
  _id: string;
  fileName: string;
  type: string;
  source: string;
  category?: string;
  [key: string]: any;
}

// Helper function to group documents by category
const groupDocumentsByCategory = (documents: DocumentType[]) => {
  const grouped: Record<string, DocumentType[]> = {};

  // Create an "Uncategorized" group for documents without a category
  documents.forEach(doc => {
    const category = doc.category || 'Uncategorized';
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push(doc);
  });

  return grouped;
};

const DocumentList: React.FC = memo(() => {
  const {
    documents,
    viewMode,
    selectedCategory,
    sortBy,
    searchTerm,
    relatedDocumentId,
    relatedModelName,
    refetch,
  } = useFileManager();

  // Track which document IDs are currently being deleted
  const [deletingDocIds, setDeletingDocIds] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    onCompleted: async (data) => {
      // Remove the document ID from the deleting list when completed
      if (data?.deletedDocId) {
        setDeletingDocIds(prevIds => prevIds.filter(id => id !== data.deletedDocId));
      }
      await refetch();
    },
  });

  const [addDocument] = useMutation(ADD_DOCUMENT, {
    onCompleted: async () => {
      await refetch();
    },
  });

  const handleDelete = async (documentId: string) => {
    try {
      // Add the document ID to the list of deleting documents
      setDeletingDocIds(prevIds => [...prevIds, documentId]);

      await deleteDocument({
        variables: {
          relatedDocumentId,
          relatedModelName,
          id: documentId,
        },
      });
    } catch (error) {
      // Remove the document ID from the deleting list if there's an error
      setDeletingDocIds(prevIds => prevIds.filter(id => id !== documentId));
      console.error('Error deleting document:', error);
    }
  };

  const handleUpload = async (files: File[]) => {
    setUploading(true);
    try {
      const validFiles = files.filter(file => validateFileSize(file));
      if (validFiles.length === 0) {
        showError({ message: 'All files exceed the maximum size limit of 50MB' });
        return;
      }

      const containerName = 'documents';
      const response = await fetch(
        `${process?.env.REACT_APP_GRAPHQL_BASE_URL}/api/documents/sasToken?containerName=${containerName}`,
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch SAS token');
      }

      for (const file of validFiles) {
        const fileName = `${Date.now()}_${file.name}`;
        const url = await uploadFileToAzureBlob({
          fileName,
          file,
          blobContainerName: 'documents',
          sasToken: '?' + data.sasToken,
        });

        await addDocument({
          variables: {
            relatedDocumentId,
            relatedModelName,
            url,
            fileName: file.name,
            type: file.type.split('/')[0],
            mimeType: file.type,
            source: 'internal',
          },
        });
      }
    } catch (error) {
      showError({
        message: error instanceof Error
          ? error.message
          : 'Failed to upload document(s)'
      });
    } finally {
      setUploading(false);
    }
  };

  // Filter and sort documents
  const filteredDocs = filterAndSortDocuments(documents, searchTerm, selectedCategory, sortBy);

  // If a specific category is selected, we don't need to group
  const shouldGroupByCategory = selectedCategory === 'all';

  // Group documents by category if we're showing all categories
  const groupedDocuments = shouldGroupByCategory ? groupDocumentsByCategory(filteredDocs) : { [selectedCategory]: filteredDocs };

  // No documents found
  if (filteredDocs.length === 0) {
    return (
      <div className="space-y-6">
        <Dropzone
          onDrop={handleUpload}
          accept={['application/pdf', 'image/*', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
          loading={uploading}
        >
          <div className="text-center p-8">
            <div className="text-center text-gray-500 my-24">No documents found. Drag and drop files here or click to select files</div>
          </div>
        </Dropzone>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {Object.entries(groupedDocuments).map(([category, docs]) => (
        <div key={category} className="mb-4">
          {shouldGroupByCategory && (
            <h3 className="text-lg font-bold text-gray-700">{category}</h3>
          )}
          <div
            className={`
              ${viewMode === 'grid'
                ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'
                : 'flex flex-col gap-2'
              }
            `}
          >
            {docs.map((doc: DocumentType) => (
              <Document
                key={doc._id}
                document={doc}
                viewMode={viewMode}
                onDelete={handleDelete}
                deletingDocIds={deletingDocIds}
                relatedDocumentId={relatedDocumentId}
                relatedModelName={relatedModelName}
                refetchQueries={[
                  {
                    query: GET_DOCUMENTS_BY_RELATED_ID,
                    variables: { relatedDocumentId, relatedModelName },
                  },
                ]}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
});

DocumentList.displayName = 'DocumentList';

export default DocumentList; 