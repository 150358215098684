import { useState } from 'react';
import Button from '../Button';
import Modal from '../Modal';
import Card from '../Card';
import ResetUserPasswordForm from './ResetUserPasswordForm';

const ResetUserPassword = ({ data, title }) => {
  const { id, email } = data[0];
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <Card
      title={title}
      floating
      actionComponent={
        <div>
          <Modal visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)}>
            <ResetUserPasswordForm
              onComplete={() => setIsModalVisible(false)}
              userId={id}
              userEmail={email}
            />
          </Modal>
          <Button
            // disabled={email.includes(config['sso-email-domain'])} // show for all users
            onClick={() => setIsModalVisible(true)}
          >
            Reset User Password
          </Button>
        </div>
      }
    />
  );
};

export default ResetUserPassword;
