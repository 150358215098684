import React, { memo } from 'react';
import { Group, TextInput, Select, Button } from '@mantine/core';
import { useFileManager } from './FileManagerContext';
import useTaxonomy from '../../../../hooks/useTaxonomy';

interface DocumentFiltersProps {
  onAddDocument: () => void;
}

const DocumentFilters: React.FC<DocumentFiltersProps> = memo(({ onAddDocument }) => {
  const {
    selectedCategory,
    setSelectedCategory,
    sortBy,
    setSortBy,
    searchTerm,
    setSearchTerm,
  } = useFileManager();

  // Get categories from taxonomy
  const { data: categoriesData } = useTaxonomy('Document Categories');
  const categories = categoriesData?.taxonomy?.terms || [];

  return (
    <Group position="apart" mb={20}>
      <Group>
        <TextInput
          placeholder="Search documents..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 200 }}
          aria-label="Search documents"
        />
        <Select
          value={selectedCategory}
          onChange={(value) => setSelectedCategory(value || 'all')}
          data={[
            { value: 'all', label: 'All Categories' },
            ...categories.map((cat: any) => ({ value: cat.name, label: cat.name })),
          ]}
          style={{ width: 150 }}
          aria-label="Filter by category"
          withinPortal={true}
        />
        <Select
          value={sortBy}
          onChange={(value: 'name' | 'date' | 'type') => setSortBy(value)}
          data={[
            { value: 'date', label: 'Sort by Date' },
            { value: 'name', label: 'Sort by Name' },
            { value: 'type', label: 'Sort by Type' },
          ]}
          style={{ width: 150 }}
          aria-label="Sort documents"
        />
      </Group>
      <Group>
        {/* <SegmentedControl
          value={viewMode}
          onChange={(value: 'grid' | 'list') => setViewMode(value)}
          data={[
            { label: 'List', value: 'list' },
            { label: 'Grid', value: 'grid' },
          ]}
          aria-label="Change view mode"
        /> */}
        <Button onClick={onAddDocument}>Add Document</Button>
      </Group>
    </Group>
  );
});

DocumentFilters.displayName = 'DocumentFilters';

export default DocumentFilters; 