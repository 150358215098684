import { useQuery } from '@apollo/react-hooks';
import numeral from 'numeral';
import gql from 'graphql-tag';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import ProjectsReportGrid from './ProjectsReportGrid';

const GET_ALL_PROJECTS = gql`
  fragment project on Project {
    _id
    projectCode
    name
    archived
    budget
    budgetUsed
    owner
    status
    sectors
    totalBilledAmount
    totalApprovedHours
    totalCost
    client {
      _id
      name
    }
  }
  query getAllProjects(
    $offset: Int
    $limit: Int
    $search: String
    $budgetUsed: Float
    $sortBy: String
    $sortOrder: Int
    $client: String
  ) {
    projectManagement {
      allProjects(
        offset: $offset
        limit: $limit
        search: $search
        budgetUsed: $budgetUsed
        sortBy: $sortBy
        sortOrder: $sortOrder
        client: $client
      ) {
        ...project
      }
    }
  }
`;

let exportFunc;

const handleExportClick = () => exportFunc();

const onExportReady = ({ getExportFunc }) => {
  exportFunc = getExportFunc();
};

const constructGridData = data => {
  return data?.projectManagement?.allProjects || [];
};

const ProjectsReport = () => {
  const { loading, data } = useQuery(GET_ALL_PROJECTS, {
    variables: {
      limit: -1,
    },
  });

  const gridData = constructGridData(data);

  const updateGridData = gridData.map(p => {
    const budget = p.budget;
    // p.budgetUsed is actually a percentage in decimal form
    const budgetUsedPct = p.budgetUsed;
    const budgetRemaining = Math.max(0, budget - budget * budgetUsedPct);
    const overBudget = budgetUsedPct > 1;

    return {
      ...p,
      budgetRemaining: numeral(budgetRemaining).format('$0,0'),
      budget: numeral(budget).format('$0,0'),
      overBudget: overBudget ? 'Yes' : 'No',
      archived: p.archived ? 'Yes' : 'No',
      sectors: p.sectors.join(', '),
    };
  });

  return (
    <Card
      loading={loading}
      border
      title="Projects"
      floating
      padded={false}
      actionComponent={
        <div className="flex">
          <div className="pl-2">
            <Button onClick={handleExportClick}>Export</Button>
          </div>
        </div>
      }
    >
      <ProjectsReportGrid onExportReady={onExportReady} rowData={updateGridData} />
    </Card>
  );
};

export default ProjectsReport;
