import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UserListContainer from './UserListContainer';
import UserDetailsContainer from './UserDetailsContainer';
import ProjectTemplates from './ProjectTemplates';
import ProjectTemplatesNew from './ProjectTemplatesNew';
import ProjectTemplateDetails from './ProjectTemplateDetails';
import TaxonomyPage from './TaxonomyPage';
import ClientsPage from './ClientsPage.tsx';
import Page from '../../common/components/Page';
import SettingsContainer from '../../super-admin/components/SettingsContainer';

const AdminPage = () => (
  <Page full>
    <Switch>
      <Route exact path="/admin" render={() => <Redirect to="/admin/users" />} />
      <Route exact path="/admin/users" render={() => <UserListContainer />} />     
      <Route exact path="/admin/clients" render={() => <ClientsPage />} />
      <Route exact path="/admin/users/:userId" render={() => <UserDetailsContainer />} />
      <Route exact path="/admin/project-templates" render={() => <ProjectTemplates />} />
      <Route exact path="/admin/project-templates/new" render={() => <ProjectTemplatesNew />} />
      <Route exact path="/admin/project-templates/:projectId" render={() => <ProjectTemplateDetails />} />
      <Route exact path="/admin/taxonomy" render={() => <TaxonomyPage />} />
      <Route exact path="/admin/settings" render={() => <SettingsContainer />} />
    </Switch>
  </Page>
);

export default AdminPage;
