import gql from 'graphql-tag';

// GraphQL query for fetching documents
export const GET_DOCUMENTS_BY_RELATED_ID = gql`
  query getDocumentsByRelatedId($relatedDocumentId: String, $relatedModelName: String) {
    projectManagement {
      documents(relatedDocumentId: $relatedDocumentId, relatedModelName: $relatedModelName) {
        _id
        fileName
        url
        type
        source
        category
        createdAt
      }
    }
  }
`; 