import React from 'react';
import { CalendarTimeTicksProps } from './types';

const formatHour = (hour: number): string => {
  const period = hour >= 12 ? 'pm' : 'am';
  const formattedHour = hour % 12 || 12;
  return `${formattedHour}${period}`;
};

const CalendarTimeTicks: React.FC<CalendarTimeTicksProps> = ({ 
  dayStart, 
  dayEnd, 
  height, 
  isHovering,
  isCreatingTask = false,
  isAdjustingTask = false
}) => {
  const hoursInDay = dayEnd - dayStart;

  // Generate two arrays - one for always-visible 6-hour increments, one for hourly increments
  const increment = 3;
  const alternateIncrements = [];
  const hourlyIncrements = [];
  
  for (let i = dayStart + 1; i < dayEnd; i++) {
    if (i % increment === 0) {
      alternateIncrements.push(i);
    } else if (isCreatingTask || isAdjustingTask) {
      hourlyIncrements.push(i);
    }
  }

  return (
      <div className="absolute left-0 top-0 bottom-0 w-6 pointer-events-none z-[1]">
        {/* Always visible 6-hour increments */}
        {alternateIncrements.map((hour) => (
          <div
            key={`left-${hour}`}
            className="absolute flex items-center"
            style={{ 
              top: `${((hour - dayStart) / hoursInDay) * height}px`,
              transform: 'translateY(-50%)'
            }}
          >
            <div className="w-2 h-px bg-gray-300"></div>
            <div className={`text-[10px] text-gray-600 ml-1 transition-opacity duration-300 ${isHovering ? 'opacity-100' : 'opacity-0'}`}>
              {formatHour(hour)}
            </div>
          </div>
        ))}
        
        {/* Hourly increments that fade in when creating or adjusting task */}
        {hourlyIncrements.map((hour) => (
          <div
            key={`left-${hour}`}
            className="absolute flex items-center transition-opacity duration-300 ease-in-out"
            style={{ 
              top: `${((hour - dayStart) / hoursInDay) * height}px`,
              transform: 'translateY(-50%)',
              opacity: (isCreatingTask || isAdjustingTask) ? 1 : 0
            }}
          >
            <div className="w-2 h-px bg-gray-300"></div>
            <div className={`text-[10px] text-gray-600 ml-1 transition-opacity duration-300 ${isHovering ? 'opacity-100' : 'opacity-0'}`}>
              {formatHour(hour)}
            </div>
          </div>
        ))}
      </div>
  );
};

export default CalendarTimeTicks; 