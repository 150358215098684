import moment from 'moment';
import gql from 'graphql-tag';
import { get, keyBy, values } from 'lodash';
import client from '../../api/graphql/client';

const GET_PROJECTS = gql`
  query getProjects($projectIds: [String]) {
    finance {
      projects(projectIds: $projectIds) {
        _id
        name
        projectCode
        client {
          _id
          shortName
        }
        roles {
          name
          rate
        }
        resources {
          _id
          name
          role
          rate
        }
      }
    }
  }
`;

const fetchTimesheetsForPeriod = async ({ startDate, endDate, setLoadingText, query }) => {
  let start = moment(startDate);
  let end = moment(endDate);
  let current = start.clone();
  let fetchedData = [];

  while (current.isBefore(end)) {
    setLoadingText(`Loading ${current.format('MMMM YYYY')}`); // Set loading false after fetching
    let monthEnd = moment(current).endOf('month');
    if (monthEnd.isAfter(end)) {
      monthEnd = end.clone();
    }
    const { data } = await client.query({
      query: query,
      variables: {
        startDate: current.format('YYYYMMDD'),
        endDate: monthEnd.format('YYYYMMDD'),
      },
    });

    fetchedData.push(...(get(data, 'finance.timesheetSnapshots') || []));
    current = monthEnd.add(1, 'day');
  }

  const hashMap = keyBy(fetchedData, '_id');
  const finalFetchedData = values(hashMap);

  const allLineItems = fetchedData.map(ts => ts.lineItems).flat();
  const allProjectIds = allLineItems.map(li => li.project._id);
  const uniqueProjectIds = [...new Set(allProjectIds)];
  const { data: projectsData } = await client.query({
    query: GET_PROJECTS,
    variables: {
      projectIds: uniqueProjectIds,
    },
  });
  const projects = projectsData.finance.projects;
  console.log({ projects })

  const updatedTimesheets = finalFetchedData.map(ts => {
    const newLinesItems = ts.lineItems.map(li => {
      const project = projects.find(p => p._id === li.project._id);
      return {
        ...li,
        project,
      };
    });
    return {
      ...ts,
      lineItems: newLinesItems,
    };
  });

  console.log({
    updatedTimesheets,
  });

  return updatedTimesheets;
};

export { fetchTimesheetsForPeriod };
