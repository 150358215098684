import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_DOCUMENTS_BY_RELATED_ID } from './queries';

interface FileManagerContextProps {
  // State
  viewMode: 'grid' | 'list';
  selectedCategory: string;
  sortBy: 'name' | 'date' | 'type';
  searchTerm: string;
  modalOpened: boolean;
  documentSource: 'internal' | 'external';
  externalForm: {
    url: string;
    fileName: string;
    category: string;
  };
  uploadedFiles: Array<{
    name: string;
    status: 'uploading' | 'completed' | 'error';
    error?: string;
  }>;
  
  // Edit mode state
  isEditMode: boolean;
  documentToEdit: {
    id: string;
    fileName: string;
    category: string;
    source: string;
  } | null;
  
  // Document data
  documents: any[];
  loading: boolean;
  refetch: () => Promise<any>;
  
  // Actions
  setViewMode: (mode: 'grid' | 'list') => void;
  setSelectedCategory: (category: string) => void;
  setSortBy: (sort: 'name' | 'date' | 'type') => void;
  setSearchTerm: (term: string) => void;
  setModalOpened: (opened: boolean) => void;
  setDocumentSource: (source: 'internal' | 'external') => void;
  updateExternalForm: (field: string, value: string) => void;
  resetExternalForm: () => void;
  setUploadedFiles: React.Dispatch<React.SetStateAction<Array<{
    name: string;
    status: 'uploading' | 'completed' | 'error';
    error?: string;
  }>>>;
  closeModal: () => void;
  
  // Edit actions
  openEditModal: (document: { _id: string; fileName: string; category?: string; source: string }) => void;
  closeEditModal: () => void;
  
  // Props passed from parent
  relatedDocumentId: string;
  relatedModelName: string;
}

const FileManagerContext = createContext<FileManagerContextProps | undefined>(undefined);

export const useFileManager = () => {
  const context = useContext(FileManagerContext);
  if (!context) {
    throw new Error('useFileManager must be used within a FileManagerProvider');
  }
  return context;
};

interface FileManagerProviderProps {
  children: ReactNode;
  relatedDocumentId: string;
  relatedModelName: string;
}

export const FileManagerProvider: React.FC<FileManagerProviderProps> = ({
  children,
  relatedDocumentId,
  relatedModelName,
}) => {
  // State for UI
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [sortBy, setSortBy] = useState<'name' | 'date' | 'type'>('date');
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpened, setModalOpened] = useState(false);
  const [documentSource, setDocumentSource] = useState<'internal' | 'external'>('internal');
  const [externalForm, setExternalForm] = useState({
    url: '',
    fileName: '',
    category: '',
  });
  const [uploadedFiles, setUploadedFiles] = useState<
    Array<{
      name: string;
      status: 'uploading' | 'completed' | 'error';
      error?: string;
    }>
  >([]);
  
  // Edit mode state
  const [isEditMode, setIsEditMode] = useState(false);
  const [documentToEdit, setDocumentToEdit] = useState<{
    id: string;
    fileName: string;
    category: string;
    source: string;
  } | null>(null);

  // Fetch documents
  const { data, loading, refetch } = useQuery(GET_DOCUMENTS_BY_RELATED_ID, {
    variables: { relatedDocumentId, relatedModelName },
  });

  const documents = data?.projectManagement?.documents || [];

  // Methods
  const updateExternalForm = (field: string, value: string) => {
    setExternalForm(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const resetExternalForm = () => {
    setExternalForm({
      url: '',
      fileName: '',
      category: '',
    });
  };

  const closeModal = () => {
    setModalOpened(false);
    resetExternalForm();
    setUploadedFiles([]);
  };
  
  // Edit mode methods
  const openEditModal = (document: { _id: string; fileName: string; category?: string; source: string }) => {
    setDocumentToEdit({
      id: document._id,
      fileName: document.fileName,
      category: document.category || '',
      source: document.source,
    });
    setIsEditMode(true);
    setModalOpened(true);
  };
  
  const closeEditModal = () => {
    setIsEditMode(false);
    setDocumentToEdit(null);
    closeModal();
  };

  const value: FileManagerContextProps = {
    // State
    viewMode,
    selectedCategory,
    sortBy,
    searchTerm,
    modalOpened,
    documentSource,
    externalForm,
    uploadedFiles,
    
    // Edit mode state
    isEditMode,
    documentToEdit,
    
    // Document data
    documents,
    loading,
    refetch,
    
    // Actions
    setViewMode,
    setSelectedCategory,
    setSortBy,
    setSearchTerm,
    setModalOpened,
    setDocumentSource,
    updateExternalForm,
    resetExternalForm,
    setUploadedFiles,
    closeModal,
    
    // Edit actions
    openEditModal,
    closeEditModal,
    
    // Props passed from parent
    relatedDocumentId,
    relatedModelName,
  };

  return <FileManagerContext.Provider value={value}>{children}</FileManagerContext.Provider>;
}; 