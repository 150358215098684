import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { setGlobal } from 'reactn';
import classNames from 'classnames';

export const GET_PROJECT_RESOURCES = gql`
    query GetProjectResources($projectId: ID!, $userId: ID!) {
        projectManagement {
            resources(projectId: $projectId, userId: $userId) {
                _id
                totalApprovedHours
                budgetedHours
            }
        }
    }
`;

const ProjectResourceHoursIndicator = ({
  projectId,
  userId,
  currentTimesheetHours,
  onNoRemainingHours,
}: {
  projectId: string;
  userId: string;
  currentTimesheetHours: number;
  onNoRemainingHours: () => void;
}) => {
  const { data, loading } = useQuery(GET_PROJECT_RESOURCES, {
    variables: { projectId, userId },
  });

  // a single user can have multiple resources for the same project
  const resources = data?.projectManagement?.resources || [];
  const hasBudgetedHours = resources.some((resource: { budgetedHours?: number }) => resource.budgetedHours);
  const totalBudgetedHours = resources.reduce((acc: number, resource: { budgetedHours: number }) => acc + resource.budgetedHours, 0);
  const totalRemainingProjectHours = resources.reduce((acc: number, resource: { budgetedHours: number; totalApprovedHours: number }) =>
    acc + (resource.budgetedHours - resource.totalApprovedHours), 0);
  const totalRemainingHours = Math.max(0, totalRemainingProjectHours - currentTimesheetHours);

  if (loading || !hasBudgetedHours) return null;

  if (totalRemainingHours <= 0) {
    setGlobal({
      hoursRemaining: 0,
    });
    onNoRemainingHours();
  }

  return <div className="text-sm text-gray-500">Your remaining hours: <span className={classNames('font-bold', {
    'text-red-300': totalRemainingHours === 0,
  })}>{totalRemainingHours} / {totalBudgetedHours}</span></div>;
};

export default ProjectResourceHoursIndicator;
