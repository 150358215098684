import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import ProjectDetails from './ProjectDetails';
import TextLoader from '../../common/components/TextLoader';
import { GET_PROJECT_BY_ID, GET_DELIVERABLES_BY_PROJECT_ID } from '../queries';

const mapStateToProps = state => ({
  user: state.meteor.user,
});

const ProjectDetailsContainer = ({ match, history, location, user, query = GET_PROJECT_BY_ID }) => (
  <Query query={query} variables={{ projectId: match.params.projectId }}>
    {({ loading, error, data, refetch }) => {
      const { location } = history;
      const { pathname } = location;
      if (error) throw new Error(error);
      if (loading) {
        return <TextLoader text="Loading Project Details" />;
      }
      const { activeTab } = match.params;
      let project;
      if (pathname.includes('finance')) {
        project = get(data, 'finance.project');
      } else {
        project = get(data, 'projectManagement.project');
      }
      return (
        <ProjectDetails
          refetch={refetch}
          user={user}
          activeTab={activeTab}
          goToUrl={url => history.replace(url)}
          history={history}
          project={project}
          refetchQueries={[
            {
              query: GET_PROJECT_BY_ID,
              variables: { projectId: match.params.projectId },
            },
            {
              query: GET_DELIVERABLES_BY_PROJECT_ID,
              variables: { projectId: match.params.projectId },
            },
          ]}
        />
      );
    }}
  </Query>
);

export default withRouter(connect(mapStateToProps)(ProjectDetailsContainer));
