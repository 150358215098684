import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import TimesheetCardContainer from './TimesheetCardContainer';
import TimesheetListCard from './TimesheetListCard';

const TimesheetsPage = props => (
  <Switch>
    <Route exact path="/user" render={() => <Redirect to="/user/timesheets" />} />
    <Route exact path="/user/timesheets" render={() => <TimesheetListCard />} />
    <Route path="/user/timesheets/:endDate" render={() => <TimesheetCardContainer />} />
  </Switch>
);

export default TimesheetsPage;
