import React, { useRef, useEffect } from 'react';
import { Modal, SegmentedControl, TextInput, Select, Button } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useMutation } from '@apollo/react-hooks';
import { showError } from '../../../common/notifications';
import { uploadFileToAzureBlob } from '../../../common';
import { useFileManager } from './FileManagerContext';
import { determineTypeFromMimeType, guessMimeTypeFromUrl, validateFileSize } from './utils';
import useTaxonomy from '../../../../hooks/useTaxonomy';
import { ADD_DOCUMENT, UPDATE_DOCUMENT } from './mutations';

const allowedFileTypes = [
  'application/pdf',
  'image/*',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'application/rtf',
  'application/zip',
  'application/x-zip-compressed',
  'application/x-7z-compressed',
  'application/x-rar-compressed'
];

const DocumentUploadModal: React.FC = () => {
  const {
    modalOpened,
    closeModal,
    documentSource,
    setDocumentSource,
    externalForm,
    updateExternalForm,
    uploadedFiles,
    setUploadedFiles,
    relatedDocumentId,
    relatedModelName,
    refetch,
    // Edit mode props
    isEditMode,
    documentToEdit,
    closeEditModal,
  } = useFileManager();

  const [addDocument, { loading: uploading }] = useMutation(ADD_DOCUMENT, {
    onCompleted: async () => {
      await refetch();
    },
  });

  const [updateDocument, { loading: updating }] = useMutation(UPDATE_DOCUMENT, {
    onCompleted: async () => {
      await refetch();
    },
  });

  const openRef = useRef<() => void>(null);
  
  // Set form data when editing
  useEffect(() => {
    if (isEditMode && documentToEdit) {
      setDocumentSource(documentToEdit.source as 'internal' | 'external');
      updateExternalForm('fileName', documentToEdit.fileName);
      updateExternalForm('category', documentToEdit.category);
    }
  }, [isEditMode, documentToEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handler for uploading files
  const uploadHandler = async (payload: File[] | File) => {
    const files = Array.isArray(payload) ? payload : [payload];

    // Check file sizes before proceeding
    const validFiles = files.filter(file => validateFileSize(file));
    if (validFiles.length === 0) {
      showError({ message: 'All files exceed the maximum size limit of 50MB' });
      return;
    }

    // Initialize upload status for all files
    setUploadedFiles(
      validFiles.map(file => ({
        name: file.name,
        status: 'uploading',
      })),
    );

    const containerName = 'documents';
    try {
      const response = await fetch(
        `${process?.env.REACT_APP_GRAPHQL_BASE_URL}/api/documents/sasToken?containerName=${containerName}`,
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch SAS token');
      }

      // Upload each file sequentially
      for (let index = 0; index < validFiles.length; index++) {
        const file = validFiles[index];
        try {
          const { name } = file;
          const fileName = `${Date.now()}_${name}`;
          const type = determineTypeFromMimeType(file.type);

          const url = await uploadFileToAzureBlob({
            fileName,
            file,
            blobContainerName: 'documents',
            sasToken: '?' + data.sasToken,
          });

          await addDocument({
            variables: {
              relatedDocumentId,
              relatedModelName,
              url,
              fileName: name,
              type,
              mimeType: file.type,
              source: 'internal',
              category: externalForm.category,
            },
          });

          // Update status to completed
          setUploadedFiles(prev =>
            prev.map((f, i) => (i === index ? { ...f, status: 'completed' } : f)),
          );
        } catch (error) {
          // Update status to error
          setUploadedFiles(prev =>
            prev.map((f, i) =>
              i === index
                ? {
                    ...f,
                    status: 'error',
                    error: error instanceof Error ? error.message : 'Upload failed',
                  }
                : f,
            ),
          );
        }
      }
    } catch (error) {
      showError({ 
        message: error instanceof Error 
          ? error.message 
          : 'Failed to upload document(s)'
      });
    }
  };

  // Handle external document form submission
  const handleExternalDocumentSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { url, fileName, category } = externalForm;

    if (isEditMode && documentToEdit) {
      try {
        await updateDocument({
          variables: {
            id: documentToEdit.id,
            fileName,
            category,
          },
        });
        closeEditModal();
      } catch (error) {
        showError({ 
          message: error instanceof Error 
            ? error.message 
            : 'Failed to update document'
        });
      }
      return;
    }

    // Handle new document creation (existing code)
    if (!url || !fileName) {
      showError({ message: 'Please provide both URL and file name' });
      return;
    }

    const mimeType = guessMimeTypeFromUrl(url);
    const type = determineTypeFromMimeType(mimeType);

    try {
      await addDocument({
        variables: {
          relatedDocumentId,
          relatedModelName,
          url,
          fileName,
          type,
          mimeType,
          source: 'external',
          category,
        },
      });
      closeModal();
    } catch (error) {
      showError({ 
        message: error instanceof Error 
          ? error.message 
          : 'Failed to add external document'
      });
    }
  };

  // Handle internal document editing (category update only)
  const handleInternalDocumentEdit = async () => {
    if (isEditMode && documentToEdit) {
      try {
        await updateDocument({
          variables: {
            id: documentToEdit.id,
            category: externalForm.category,
          },
        });
        closeEditModal();
      } catch (error) {
        showError({ 
          message: error instanceof Error 
            ? error.message 
            : 'Failed to update document category'
        });
      }
    }
  };

  // Get categories from taxonomy
  const { data: categoriesData } = useTaxonomy('Document Categories');
  const categories = categoriesData?.taxonomy?.terms || [];

  const modalTitle = isEditMode ? "Edit Document" : "Add Document";
  const isSubmitLoading = uploading || updating;

  return (
    <Modal opened={modalOpened} onClose={isEditMode ? closeEditModal : closeModal} title={modalTitle} size="md">
      {!isEditMode && (
        <div style={{ marginBottom: 20 }}>
          <SegmentedControl
            value={documentSource}
            onChange={setDocumentSource}
            data={[
              { label: 'Upload File', value: 'internal' },
              { label: 'External Link', value: 'external' },
            ]}
            fullWidth
          />
        </div>
      )}

      {(documentSource === 'internal' && !isEditMode) ? (
        <>
          <Select
            placeholder="Select a category"
            value={externalForm.category}
            onChange={value => updateExternalForm('category', value || '')}
            data={categories.map((cat: any) => ({ value: cat.name, label: cat.name }))}
            style={{ marginBottom: 15 }}
            clearable
            withinPortal={true}
          />
          <Dropzone
            accept={allowedFileTypes}
            openRef={openRef}
            onDrop={async (files: File | File[]) => {
              await uploadHandler(files);
            }}
            activateOnClick={true}
            styles={{ inner: { pointerEvents: 'all' } }}
          >
            <div className="text-center p-8">
              <div className="text-gray-600">Drag files here or click to select files</div>
            </div>
          </Dropzone>

          {uploadedFiles.length > 0 && (
            <div style={{ marginTop: 20, padding: 10 }}>
              <h4>Upload Status:</h4>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    marginTop: 5,
                    padding: 5,
                    backgroundColor: file.status === 'error' ? '#fff1f0' : '#f6ffed',
                  }}
                >
                  <span>{file.name}</span>
                  <span style={{ marginLeft: 'auto' }}>
                    {file.status === 'uploading' && 'Uploading...'}
                    {file.status === 'completed' && '✓ Completed'}
                    {file.status === 'error' && `❌ ${file.error || 'Failed'}`}
                  </span>
                </div>
              ))}
              {uploadedFiles.every(file => file.status !== 'uploading') && (
                <Button onClick={closeModal} style={{ marginTop: 15 }} fullWidth>
                  Close
                </Button>
              )}
            </div>
          )}
        </>
      ) : (
        // Handle both external documents and editing
        <form
          onSubmit={handleExternalDocumentSubmit}
          style={{ display: 'flex', flexDirection: 'column', gap: 15 }}
        >
          {/* For internal documents in edit mode, only show category editing */}
          {isEditMode && documentToEdit && documentToEdit.source === 'internal' ? (
            <>
              <Select
                label="Category"
                placeholder="Select a category"
                value={externalForm.category}
                onChange={value => updateExternalForm('category', value || '')}
                data={categories.map((cat: any) => ({ value: cat.name, label: cat.name }))}
                withinPortal={true}
              />
              <Button 
                onClick={handleInternalDocumentEdit} 
                loading={isSubmitLoading} 
                style={{ marginTop: 10 }}
              >
                Update Category
              </Button>
            </>
          ) : (
            // For external documents (both new and edit mode)
            <>
              <TextInput
                label="File Name"
                placeholder="Enter file name"
                value={externalForm.fileName}
                onChange={e => updateExternalForm('fileName', e.target.value)}
                required
              />
              {!isEditMode && (
                <TextInput
                  label="External URL"
                  placeholder="https://example.com/document.pdf"
                  value={externalForm.url}
                  onChange={e => updateExternalForm('url', e.target.value)}
                  required
                />
              )}
              <div style={{ marginBottom: 15 }}>
                <Select
                  label="Category"
                  placeholder="Select a category"
                  value={externalForm.category}
                  onChange={value => updateExternalForm('category', value || '')}
                  data={categories.map((cat: any) => ({ value: cat.name, label: cat.name }))}
                  withinPortal={true}
                />
              </div>
              <Button type="submit" loading={isSubmitLoading} style={{ marginTop: 10 }}>
                {isEditMode ? 'Update Document' : 'Add External Document'}
              </Button>
            </>
          )}
        </form>
      )}
    </Modal>
  );
};

export default DocumentUploadModal; 