import React, { useState } from 'react';
// import { useGlobal } from 'reactn';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import Modal from '../../common/components/Modal';
import Button from '../../common/components/Button';
import UpdateResourceForm from '../../common/components/UpdateResourceForm';
import { REMOVE_RESOURCE } from '../../common/components/UpdateResourceForm/mutations';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ProjectDetailsResourceActions = ({ roles, user, resource, refetchQueries }) => {
  // const [lockEditing] = useGlobal('lockEditing');
  const [removeResource] = useMutation(REMOVE_RESOURCE);
  const [updateResourceVisible, setUpdateResourceVisible] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  return (
    <ActionsWrapper>
      <div className="flex gap-2">
        <Button
          // disabled={lockEditing}
          onClick={() => setUpdateResourceVisible(true)}
        >
          Edit
        </Button>

        <Button
          disabled={resource.totalApprovedHours > 0}
          loading={isRemoving}
          type="danger"
          icon="close"
          onClick={async () => {
            setIsRemoving(true);
            await removeResource({
              variables: {
                id: resource._id,
              },
              refetchQueries,
              awaitRefetchQueries: true,
            });
          }}
        />
      </div>
      <Modal
        title="Update Resource"
        visible={updateResourceVisible}
        destroyOnClose
        onOk={() => setUpdateResourceVisible(false)}
        onCancel={() => setUpdateResourceVisible(false)}
        footer={null}
      >
        <UpdateResourceForm
          roles={roles}
          resource={resource}
          refetchQueries={refetchQueries}
          onComplete={() => setUpdateResourceVisible(false)}
        />
      </Modal>
    </ActionsWrapper>
  );
};

const mapStateToProps = state => ({
  user: state.meteor?.user,
});

export default connect(mapStateToProps)(ProjectDetailsResourceActions);
