/**
 * Determines the document type based on MIME type
 */
export const determineTypeFromMimeType = (mimeType: string): string => {
  if (!mimeType) return 'other';

  const type = mimeType.split('/')[0];
  const subtype = mimeType.split('/')[1];

  switch (type) {
    case 'application':
      switch (subtype) {
        case 'pdf':
          return 'PDF';
        case 'msword':
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'vnd.oasis.opendocument.text':
          return 'Document';
        case 'vnd.ms-excel':
        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'vnd.oasis.opendocument.spreadsheet':
          return 'Spreadsheet';
        case 'vnd.ms-powerpoint':
        case 'vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'vnd.oasis.opendocument.presentation':
          return 'Presentation';
        case 'zip':
        case 'x-rar-compressed':
        case 'x-7z-compressed':
        case 'x-tar':
        case 'x-gzip':
          return 'Archive';
        default:
          return 'other';
      }
    case 'image':
      return 'Image';
    case 'text':
      return 'Text';
    case 'audio':
      return 'Audio';
    case 'video':
      return 'Video';
    default:
      return 'other';
  }
};

/**
 * Guesses a MIME type from a URL or filename
 */
export const guessMimeTypeFromUrl = (url: string): string => {
  const urlLower = url.toLowerCase();
  
  // Check for common file extensions and assign appropriate MIME types
  if (urlLower.includes('.pdf')) {
    return 'application/pdf';
  } else if (urlLower.includes('.jpg') || urlLower.includes('.jpeg')) {
    return 'image/jpeg';
  } else if (urlLower.includes('.png')) {
    return 'image/png';
  } else if (urlLower.includes('.gif')) {
    return 'image/gif';
  } else if (urlLower.includes('.doc')) {
    return 'application/msword';
  } else if (urlLower.includes('.docx')) {
    return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  } else if (urlLower.includes('.xls')) {
    return 'application/vnd.ms-excel';
  } else if (urlLower.includes('.xlsx')) {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  } else if (urlLower.includes('.ppt')) {
    return 'application/vnd.ms-powerpoint';
  } else if (urlLower.includes('.pptx')) {
    return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
  } else if (urlLower.includes('.txt')) {
    return 'text/plain';
  } else if (urlLower.includes('.html') || urlLower.includes('.htm')) {
    return 'text/html';
  }
  
  return 'application/octet-stream'; // Default MIME type
};

/**
 * Validate file size
 * @param file The file to validate
 * @param maxSizeMB Maximum file size in MB
 * @returns boolean indicating if the file size is valid
 */
export const validateFileSize = (file: File, maxSizeMB: number = 50): boolean => {
  return file.size / 1024 / 1024 < maxSizeMB;
};

/**
 * Filter and sort documents based on search term, category, and sort option
 */
export const filterAndSortDocuments = (
  documents: any[],
  searchTerm: string,
  selectedCategory: string,
  sortBy: 'name' | 'date' | 'type'
) => {
  return documents
    .filter((doc: any) => {
      const matchesSearch = doc.fileName.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategory === 'all' || doc.category === selectedCategory;
      return matchesSearch && matchesCategory;
    })
    .sort((a: any, b: any) => {
      switch (sortBy) {
        case 'name':
          return a.fileName.localeCompare(b.fileName);
        case 'type':
          return a.type.localeCompare(b.type);
        case 'date':
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        default:
          return 0;
      }
    });
}; 