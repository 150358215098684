import React, { useState } from 'react';
import { Button, Input, message, InputNumber, Checkbox, Progress } from 'antd';
import useTaxonomy from '../../../hooks/useTaxonomy';

interface Status {
    name: string;
}

interface BatchResponse {
    message: string;
    offset: number;
    hasMore: boolean;
}

const UpdateBudgets: React.FC = () => {
    const [projectId, setProjectId] = useState<string>('');
    const [numberOfProjectsToUpdate, setNumberOfProjectsToUpdate] = useState<number | undefined>(undefined);
    const [force, setForce] = useState<boolean>(false);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>(['In Progress']);
    const [result, setResult] = useState<{ success: boolean; message: string; count: number } | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<{ current: number; total: number } | null>(null);

    const { data: statusQuery } = useTaxonomy('Status');
    const statuses = (statusQuery?.taxonomy?.terms || []) as Status[];

    const handleStatusChange = (statusName: string) => {
        setSelectedStatuses(prev => 
            prev.includes(statusName) 
                ? prev.filter(s => s !== statusName)
                : [...prev, statusName]
        );
    };

    const processBatch = async (offset: number, batchSize: number) => {
        const forceParam = force ? '&force=true' : '';
        const statusesParam = selectedStatuses.length > 0 ? `&statuses=${selectedStatuses.join(',')}` : '';
        
        const response = await fetch(
            `${process?.env.REACT_APP_GRAPHQL_BASE_URL}/api/update-budget-used?numberOfProjectsToUpdate=${batchSize}&offset=${offset}${forceParam}${statusesParam}`,
            { method: 'POST' }
        );
        const data = await response.json() as BatchResponse;
        
        // Extract number from message like "10 projects updated successfully"
        const match = data.message.match(/(\d+)/);
        const count = match ? parseInt(match[0], 10) : 0;
        
        return {
            success: true,
            count,
            message: data.message,
            hasMore: data.hasMore
        };
    };

    const handleUpdateBudgets = async () => {
        try {
            setLoading(true);
            setResult(null);
            
            if (projectId) {
                // Single project update
                const forceParam = force ? '&force=true' : '';
                const statusesParam = selectedStatuses.length > 0 ? `&statuses=${selectedStatuses.join(',')}` : '';
                const response = await fetch(
                    `${process?.env.REACT_APP_GRAPHQL_BASE_URL}/api/update-budget-used?projectId=${projectId}${forceParam}${statusesParam}`,
                    { method: 'POST' }
                );
                const data = await response.json();
                setResult(data);
            } else {
                // Batch processing
                const totalToProcess = numberOfProjectsToUpdate || 10;
                const batchSize = 10;
                let totalProcessed = 0;
                let totalSuccessful = 0;

                setProgress({ current: 0, total: totalToProcess });

                for (let offset = 0; offset < totalToProcess; offset += batchSize) {
                    const currentBatchSize = Math.min(batchSize, totalToProcess - offset);
                    const batchResult = await processBatch(offset, currentBatchSize);
                    
                    if (batchResult.success) {
                        totalSuccessful += batchResult.count;
                    }
                    totalProcessed += currentBatchSize;
                    setProgress({ current: totalProcessed, total: totalToProcess });
                }

                setResult({
                    success: true,
                    message: 'Batch processing completed',
                    count: totalSuccessful
                });
            }
        } catch (error) {
            message.error('Failed to update budgets');
            console.error(error);
        } finally {
            setLoading(false);
            setProgress(null);
        }
    };

    return (
        <div className="flex flex-col gap-2">
            <Input
                className="w-full"
                placeholder="Project ID (optional - leave empty to update all)"
                value={projectId}
                onChange={(e) => setProjectId(e.target.value)}
            />
            <InputNumber
                className="w-full"
                placeholder="Number of projects to update (default 10)"
                value={numberOfProjectsToUpdate}
                onChange={(value) => setNumberOfProjectsToUpdate(value)}
            />
            <Checkbox
                checked={force}
                onChange={(e) => setForce(e.target.checked)}
            >
                Force update (ignore 24-hour limit)
            </Checkbox>
            <div className="flex flex-col gap-2">
                <div className="font-medium">Update by status:</div>
                <div className="flex flex-wrap gap-2">
                    {statuses.map(status => (
                        <Checkbox
                            key={status.name}
                            checked={selectedStatuses.includes(status.name)}
                            onChange={() => handleStatusChange(status.name)}
                        >
                            {status.name}
                        </Checkbox>
                    ))}
                </div>
            </div>
            <Button
                type="primary"
                onClick={handleUpdateBudgets}
                loading={loading}
            >
                Update Budgets
            </Button>
            {progress && (
                <div className="mt-2">
                    <Progress 
                        percent={Math.round((progress.current / progress.total) * 100)} 
                        status="active"
                    />
                    <div className="text-sm text-gray-600 mt-1">
                        Processing {progress.current} of {progress.total} projects...
                    </div>
                </div>
            )}
            {result && (
                <div className="mt-2">
                    {result.success ? (
                        <div className="text-green-600">
                            Successfully updated {result.count} project budget(s).
                        </div>
                    ) : (
                        <div className="text-red-600">
                            {result.message}
                        </div>
                    )}
                </div>
            )}
            <div className="text-sm text-gray-500 mt-1">
                This will recalculate and update project budgets based on the latest data.
                {force ? ' All projects will be updated.' : ' Only projects that haven\'t been updated in the last 24 hours will be updated.'}
            </div>
        </div>
    );
};

export default UpdateBudgets;
