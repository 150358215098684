import { Task } from './types';

export const calculateTimeConversions = (heightInPx: number, dayStart: number, dayEnd: number) => {
  const hoursInDay = dayEnd - dayStart;
  const pixelsPerHour = heightInPx / hoursInDay;

  const pixelsToTime = (pixels: number) => {
    return (pixels / heightInPx) * hoursInDay;
  };

  const timeToPixels = (time: number) => {
    return (time / hoursInDay) * heightInPx;
  };

  return {
    pixelsToTime,
    timeToPixels,
    hoursInDay,
    pixelsPerHour,
  };
};

export const isOverlap = (task1: Task, task2: Task): boolean => {
  return task1.startTime < task2.endTime && task1.endTime > task2.startTime;
};

export const assignPositions = (tasks: Task[]): Task[][] => {
  const positions: Task[][] = [];
  
  // Sort tasks by start time
  const sortedTasks = [...tasks].sort((a, b) => a.startTime - b.startTime);
  
  sortedTasks.forEach((task) => {
    // Try to find a position where this task doesn't overlap with any existing tasks
    let placed = false;
    for (let i = 0; i < positions.length; i++) {
      const overlapsInPosition = positions[i].some(existingTask => isOverlap(task, existingTask));
      if (!overlapsInPosition) {
        positions[i].push(task);
        placed = true;
        break;
      }
    }
    
    // If we couldn't place it in any existing position, create a new one
    if (!placed) {
      positions.push([task]);
    }
  });
  
  return positions;
};

export const calculateTotalHours = (tasks: Task[]): number => {
  return tasks.reduce((total, task) => total + (task.endTime - task.startTime), 0);
}; 