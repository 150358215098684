import React from 'react';
import gql from 'graphql-tag';
import numeral from 'numeral';
import { Progress } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import classNames from 'classnames';

export const GET_PROJECT_RESOURCE_HOURS = gql`
    query GetProjectResourceHours($resourceId: ID!) {
        projectManagement {
            resource(resourceId: $resourceId) {
                _id
                totalApprovedHours
                budgetedHours
                email
            }
        }
    }
`;

interface ProjectResourceHoursProps {
    resourceId: string;
}

const ProjectResourceHours: React.FC<ProjectResourceHoursProps> = ({ resourceId }) => {
    const { loading, data } = useQuery(GET_PROJECT_RESOURCE_HOURS, {
        variables: { resourceId },
    });

    const resource = data?.projectManagement?.resource;
    const { totalApprovedHours, budgetedHours } = resource || {};

    if (loading) return <div>Loading...</div>;

    return (
        <div>
            <div>
                <span className={classNames({ 'text-red-200 font-semibold': budgetedHours && totalApprovedHours > budgetedHours })}>{numeral(totalApprovedHours).format('0,00.[00]')}</span>
                <span className={classNames({ 'text-gray-500': !budgetedHours })}> / {budgetedHours ? numeral(budgetedHours).format('0,00.[00]') : 'Not set'}</span>
            </div>
            {resource?.budgetedHours > 0 && (
                <Progress
                    showInfo={false}
                    percent={Math.floor(
                        (totalApprovedHours / (budgetedHours || 1)) * 100,
                    )}
                    strokeColor={
                        totalApprovedHours / (budgetedHours || 1) > 1
                            ? '#f5222d'
                            : '#38A169'
                    }
                />
            )}
        </div>
    );
};

export default ProjectResourceHours;
