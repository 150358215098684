import moment from 'moment-timezone';

export interface OutlookEvent {
  id: string;
  subject: string;
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
  location?: {
    displayName?: string;
  };
}

export interface Task {
  startTime: number;
  endTime: number;
  title: string;
  color: string;
  date: string;
  data: {
    isOutlookEvent: boolean;
    eventId: string;
    location?: string;
  };
}

const CLIENT_ID = process?.env.REACT_APP_OAUTH_AZURE_CLIENT_ID || '';
const TENANT_ID = process?.env.REACT_APP_OAUTH_AZURE_TENANT_ID || '';
const REDIRECT_URI = `${window.location.origin}/outlook-callback.html`;
const SCOPES = ['user.read', 'Calendars.Read', 'Calendars.Read.Shared'].join(' ');

const getAccessToken = async (): Promise<string> => {
  // Check if we have a token in localStorage
  const token = localStorage.getItem('outlook_access_token');
  const tokenExpiry = localStorage.getItem('outlook_token_expiry');
  
  if (token && tokenExpiry && new Date(tokenExpiry) > new Date()) {
    return token;
  }

  // If no valid token, initiate OAuth flow
  const authUrl = `https://login.microsoftonline.com/${TENANT_ID}/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=${encodeURIComponent(SCOPES)}&prompt=select_account`;
  
  // Open popup window for authentication
  const popup = window.open(authUrl, 'Outlook Login', 'width=600,height=600');
  
  // Wait for the popup to close and get the token
  return new Promise((resolve, reject) => {
    const handleMessage = (event: MessageEvent) => {
      // Verify the origin of the message
      if (event.origin !== window.location.origin) return;
      
      // Check if the message contains the token
      if (event.data?.type === 'outlook_token') {
        const { access_token, expires_in } = event.data;
        
        // Store token and expiry
        localStorage.setItem('outlook_access_token', access_token);
        const expiry = new Date(Date.now() + expires_in * 1000);
        localStorage.setItem('outlook_token_expiry', expiry.toISOString());
        
        // Clean up
        window.removeEventListener('message', handleMessage);
        resolve(access_token);
      }
    };

    window.addEventListener('message', handleMessage);

    // Check if popup was blocked
    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      window.removeEventListener('message', handleMessage);
      reject(new Error('Popup was blocked. Please allow popups for this site.'));
    }
  });
};

export const fetchOutlookEvents = async (startDate: string, endDate: string): Promise<OutlookEvent[]> => {
  try {
    console.log('Starting fetchOutlookEvents with date range:', { startDate, endDate });
    const token = await getAccessToken();
    console.log('Got token, making API request...');
    
    // Convert dates to ISO 8601 format
    const formattedStartDate = moment(startDate, 'YYYYMMDD').format('YYYY-MM-DDTHH:mm:ss');
    const formattedEndDate = moment(endDate, 'YYYYMMDD').format('YYYY-MM-DDTHH:mm:ss');
    
    console.log('Formatted dates:', { formattedStartDate, formattedEndDate });
    
    // Use the calendarview endpoint with proper parameters for recurring events
    const apiUrl = `https://graph.microsoft.com/v1.0/me/calendarview?startDateTime=${formattedStartDate}&endDateTime=${formattedEndDate}&$select=id,subject,start,end,location,type,seriesMasterId,recurrence&$top=100`;
    console.log('Making request to:', apiUrl);
    
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('API response status:', response.status);
    if (!response.ok) {
      const errorText = await response.text();
      console.error('API error response:', errorText);
      // If token is invalid, clear it and try again
      if (response.status === 401) {
        console.log('Token invalid, clearing and retrying...');
        localStorage.removeItem('outlook_access_token');
        localStorage.removeItem('outlook_token_expiry');
        return fetchOutlookEvents(startDate, endDate);
      }
      throw new Error(`Failed to fetch Outlook events: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    console.log('API response data:', data);
    if (!data || !data.value) {
      console.error('Invalid API response:', data);
      return [];
    }

    // Process all events, including recurring ones
    const allEvents: OutlookEvent[] = data.value.map((event: any) => ({
      id: event.id,
      subject: event.subject,
      start: event.start,
      end: event.end,
      location: event.location,
    }));

    console.log('Successfully fetched events:', allEvents.length);
    return allEvents;
  } catch (error) {
    console.error('Error fetching Outlook events:', error);
    throw error;
  }
};

export const convertOutlookEventToTask = (event: OutlookEvent) => {
  // Parse the UTC times from Outlook
  const startTime = moment.utc(event.start.dateTime);
  const endTime = moment.utc(event.end.dateTime);

  // Convert to EST
  const startTimeEST = startTime.tz('America/New_York');
  const endTimeEST = endTime.tz('America/New_York');

  // Convert to hours since midnight
  const startHour = startTimeEST.hour() + startTimeEST.minute() / 60;
  const endHour = endTimeEST.hour() + endTimeEST.minute() / 60;

  return {
    startTime: startHour,
    endTime: endHour,
    title: event.subject,
    color: '#E5E7EB', // Light gray color for Outlook events
    date: startTimeEST.format('YYYY-MM-DD'),
    data: {
      isOutlookEvent: true,
      eventId: event.id,
      location: event.location?.displayName,
    },
  };
}; 