import React from 'react';
import { useGlobal } from 'reactn';
import Card from '../../common/components/Card';
import DateRangePicker from '../../common/components/DateRangePicker';
import TimesheetListContainer from './TimesheetListContainer';
import Page from '../../common/components/Page';

const TimesheetListCard = () => {
  const [range, setRange] = useGlobal('range');
  const { startDate, endDate } = range;

  const handleDateChange = range => {
    const [startDate, endDate] = range;
    setRange({
      startDate,
      endDate,
    });
  };

  return (
    <Page>
      <Card
        floating
        title="Timesheets"
        bodyBackground={null}
        actionComponent={
          <DateRangePicker
            handleDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
          />
        }
      >
        <TimesheetListContainer
          startDate={startDate.format('YYYYMMDD')}
          endDate={endDate.format('YYYYMMDD')}
          setRange={setRange}
        />
      </Card>
    </Page>
  );
};

export default TimesheetListCard;
