import React, { useState } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import moment from 'moment';
import { Input, Icon } from 'antd';
import Tag from '../../common/components/Tag';
import Button from '../../common/components/Button';
import Table from '../../common/components/Table';
import Tooltip from '../../common/components/Tooltip';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import Profile from '../../common/components/Profile';
import { getColorByStatus } from '../../common';
import { GET_TIMESHEETS_BY_PROJECT_ID } from '../queries';
import TimesheetDownloadButton from '../../timesheets/components/TimesheetDownloadButton';
import useEndDateStore from '../../common/stores/useEndDateStore';
// import ProjectDetailsTimesheetsChart from './ProjectDetailsTimesheetsChart';

const UnrelatedHours = styled.span`
  color: #d6d6d6;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
  > * {
    margin: 0 0.5em;
  }
`;

const createColumns = ({ timesheets, projectId, searchInput, onSearch, resetSearch }) => [
  {
    title: 'Resource',
    key: 'resource',
    width: '30%',
    render: ({ user, resource }) => {
      const { firstName, lastName, imageUrl } = user;
      const role = resource?.role;
      const email = resource?.email;

      return (
        <Profile
          imageUrl={imageUrl}
          title={role || 'Role not defined'}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      );
    },
    sorter: (a, b) => {
      const nameA = `${a.user.firstName} ${a.user.lastName}`;
      const nameB = `${b.user.firstName} ${b.user.lastName}`;
      return nameA.localeCompare(nameB);
    },
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search Name`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => onSearch(selectedKeys, confirm, 'firstName')}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => onSearch(selectedKeys, confirm, 'firstName')}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => resetSearch(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, { user, resource }) => {
      const { firstName, lastName, title } = user;
      const role = resource?.role;
      const email = resource?.email;
      const searchString = firstName + lastName + email + (role || title);
      return searchString.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  },
  {
    title: 'Week',
    dataIndex: 'endDate',
    key: 'weekOf',
    render: endDate => moment(endDate).format('L'),
  },
  {
    title: 'Hours',
    key: 'totalHours',
    render: record => {
      const lineItemTotalHours = record.lineItems
        .filter(lineItem => lineItem?.projectId === projectId)
        .reduce((acc, cur) => acc + cur.totalHours, 0);
      const hoursDiff = record.totalHours - lineItemTotalHours;
      if (hoursDiff > 0) {
        const tooltipText = `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} worked on other projects`;
        return (
          <Tooltip title={tooltipText} trigger="hover">
            <span>
              {lineItemTotalHours}
              <UnrelatedHours>{`/${record.totalHours}`}</UnrelatedHours>
            </span>
          </Tooltip>
        );
      }

      return record.totalHours;
    },
  },
  {
    title: 'Approvers',
    render: ({ approvalsRequired, status }) => {
      if (!approvalsRequired || approvalsRequired.length === 0) {
        return null;
      }
      const approvelsGiven = approvalsRequired.reduce(
        (acc, cur) => (cur.status === 'Approved' ? acc + 1 : acc),
        0,
      );
      const numberOfApprovalsRequired = approvalsRequired?.length;
      const tooltipText = approvalsRequired.reduce((acc, cur, index) => {
        if (index === 0) {
          return acc + cur.canApprove[0];
        }
        return `${acc}, ${cur.canApprove[0]}`;
      }, `Requires approval${approvalsRequired.length > 1 ? 's' : ''} from `);

      return (
        <Tooltip title={tooltipText} trigger="hover">
          <span>{`${approvelsGiven}/${numberOfApprovalsRequired}`}</span>
        </Tooltip>
      );
    },
  },
  {
    title: 'Timesheet Status',
    filters: timesheets
      .reduce((acc, cur) => {
        if (acc.find(r => r.text === cur.status)) return acc;
        return [
          ...acc,
          {
            text: cur.status,
            value: cur.status,
          },
        ];
      }, [])
      .sort((a, b) => a.value.localeCompare(b.value)),
    onFilter: (value, record) => record.status.indexOf(value) === 0,
    render: ({ status }) => {
      return <Tag color={getColorByStatus(status)}>{status}</Tag>;
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    // width: 50,
    render: timesheet => {
      const { _id: timesheetId, status } = timesheet;
      return (
        <ButtonsWrapper>
          <TimesheetDownloadButton timesheet={timesheet} />
          <Button
            href={`/reports/timesheets/${timesheetId}`}
            target="_blank"
            icon="profile"
            type="circle"
            disabled={status !== 'Approved'}
          />
        </ButtonsWrapper>
      );
    },
  },
];

const ProjectDetailsTimesheetList = ({ projectId, isPaginated = true }) => {
  const { endDate, setEndDate} = useEndDateStore();
  const [searchInput, setSearchInput] = useState(null);
  return (
    <Query
      query={GET_TIMESHEETS_BY_PROJECT_ID}
      variables={{
        projectId,
        endDate,
        showNotSubmitted: true,
      }}
    >
      {({ loading, error, data }) => {
        if (error) throw new Error(error);
        const timesheets = data?.projectManagement?.project?.timesheets || [];

        const columns = createColumns({
          projectId,
          timesheets,
          searchInput,
          resetSearch: clearFilters => {
            clearFilters();
            setSearchInput(null);
          },
          onSearch: (selectedKeys, confirm) => {
            confirm();
            setSearchInput(selectedKeys[0]);
          },
        });

        return (
          <Card
            // headerComponent={<ProjectDetailsTimesheetsChart timesheets={timesheets} />}
            actionComponent={
              <WeekPicker
                endDate={endDate}
                onDateChange={newEndDate => setEndDate(moment(newEndDate).format('YYYYMMDD'))}
              />
            }
          >
            <Table
              size="small"
              loading={loading}
              columns={columns}
              pagination={isPaginated}
              dataSource={
                // make sure timesheets exist and arrived to browser
                timesheets &&
                timesheets
                  // add key property to timesheet objects. Its used by <Table />
                  .map(t => ({ ...t, key: t._id }))
                  // sort by endDate
                  .sort((a, b) => {
                    const diff = moment(b.endDate).diff(moment(a.endDate), 'days');
                    return diff;
                  })
              }
            />
          </Card>
        );
      }}
    </Query>
  );
};

export default ProjectDetailsTimesheetList;
