import React from 'react';
import { CalendarMarkersProps } from './types';

const CalendarMarkers: React.FC<CalendarMarkersProps> = ({
  markers,
  timeToPixels,
}) => {
  return (
    <>
      {markers
        .filter(marker => (marker.condition ? marker.condition() : true))
        .map((marker, index) => (
          <div
            key={index}
            className="z-20 absolute w-full pointer-events-none"
            style={{
              height: '100%',
              backgroundColor: marker.backgroundColor,
              borderTop: `1px solid ${marker.lineColor}`,
              top: `${timeToPixels(marker.time)}px`,
            }}
          >
            <span style={{ position: 'absolute', right: 0, color: 'red' }}>
              {marker.label}
            </span>
          </div>
        ))}
    </>
  );
};

export default CalendarMarkers; 