import { useGlobal } from 'reactn';
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import numeral from 'numeral';
import { Input, InputNumber, Tooltip, Checkbox } from 'antd';
import { Formik } from 'formik';
import Table from '../../common/components/Table';
import Card from '../../common/components/Card';
import Form from '../../common/components/Form';
import Button from '../../common/components/Button';
import Popconfirm from '../../common/components/Popconfirm';
import Modal from '../../common/components/Modal';

const GET_PROJECT_EXPENSES = gql`
  query getProjectExpenses($projectId: String!) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        expenses {
          _id
          name
          amount
          comment
          date
        }
      }
    }
  }
`;

export const UPDATE_PROJECT_EXPENSE = gql`
  mutation updateProjectExpense(
    $expenseId: ID!
    $amount: Float!
    $proposedAmount: Float
    $name: String!
    $comment: String
    $date: Date
  ) {
    updateExpense(
      id: $expenseId
      input: {
        name: $name
        amount: $amount
        proposedAmount: $proposedAmount
        comment: $comment
        date: $date
      }
    )
  }
`;

export const ADD_PROJECT_EXPENSE = gql`
  mutation addProjectExpense(
    $projectId: ID!
    $amount: Float!
    $name: String!
    $comment: String
    $date: Date
  ) {
    addExpense(
      projectId: $projectId
      input: { name: $name, amount: $amount, comment: $comment, date: $date }
    )
  }
`;

export const REMOVE_PROJECT_EXPENSE = gql`
  mutation removeProjectExpense($expenseId: ID!) {
    removeExpense(expenseId: $expenseId)
  }
`;

const DEDUCT_EXPENSES = gql`
  mutation deductProjectExpenses($projectId: ID!, $value: Boolean!) {
    deductProjectExpenses(projectId: $projectId, value: $value)
  }
`;

const ProjectExpenseForm = ({ projectId, selectedExpense = {}, setShowModal }) => {
  const [updateProjectExpense] = useMutation(UPDATE_PROJECT_EXPENSE);
  const [addProjectExpense] = useMutation(ADD_PROJECT_EXPENSE);
  const { _id: expenseId } = selectedExpense;
  return (
    <Formik
      key={selectedExpense._id}
      initialValues={selectedExpense}
      validate={values => {
        const errors = {};
        if (!values.amount) {
          errors.amount = 'Amount is required';
        } else if (values.amount < 0) {
          errors.amount = 'Provide a positive value';
        }
        if (!values.name) {
          errors.name = 'Name is required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        try {
          if (expenseId) {
            await updateProjectExpense({
              variables: { expenseId, ...values },
              refetchQueries: [
                {
                  query: GET_PROJECT_EXPENSES,
                  variables: {
                    projectId,
                  },
                },
              ],
              awaitRefetchQueries: true,
            });
          } else {
            await addProjectExpense({
              variables: { projectId, ...values },
              refetchQueries: [
                {
                  query: GET_PROJECT_EXPENSES,
                  variables: {
                    projectId,
                    ...values,
                  },
                },
              ],
              awaitRefetchQueries: true,
            });
            resetForm();
          }
          setShowModal(false);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        handleChange,
        setValues,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
        resetForm,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <Form.Item
              label="Name"
              hasFeedback
              validateStatus={errors.name && touched.name && 'error'}
              help={errors.name && touched.name && errors.name}
              required
            >
              <Input
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                disabled={isSubmitting}
              />
            </Form.Item>
            <Form.Item
              label="Amount"
              validateStatus={errors.amount ? 'error' : null}
              hasFeedback={!!errors.amount}
              help={errors.amount ? errors.amount : null}
              required
            >
              <InputNumber
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={amount => setValues({ ...values, amount })}
                value={values.amount}
                disabled={isSubmitting}
                name="rate"
              />
            </Form.Item>
            <Form.Item
              label="Additional Comments"
              hasFeedback
              validateStatus={errors.comment && touched.comment && 'error'}
              help={errors.comment && touched.comment && errors.comment}
            >
              <Input
                name="comment"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comment}
                disabled={isSubmitting}
              />
            </Form.Item>
          </div>
          <div className="flex justify-end pr-3">
            <Form.Item>
              <Button disabled={!isValid} loading={isSubmitting} type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const ProjectExpenseAction = ({ removeProjectExpense, onEdit, projectId, expenseId }) => {
  const [isRemoving, setIsRemoving] = useState(false);
  return (
    <div className="flex flex-start">
      <Button className="mr-2" onClick={() => onEdit()}>
        Edit
      </Button>
      <Popconfirm
        placement="topLeft"
        title="Are you sure you want to delete this expense?"
        onConfirm={async () => {
          setIsRemoving(true);
          try {
            await removeProjectExpense({
              variables: { expenseId },
              refetchQueries: [
                {
                  query: GET_PROJECT_EXPENSES,
                  variables: {
                    projectId,
                  },
                },
              ],
              awaitRefetchQueries: true,
            });
          } catch (error) {
            console.log({ error });
            setIsRemoving(false);
          }
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button type="danger" loading={isRemoving} icon="close"></Button>
      </Popconfirm>
    </div>
  );
};

const ProjectExpenses = ({ project }) => {
  const [config] = useGlobal('config');
  const [showModal, setShowModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const { loading, data } = useQuery(GET_PROJECT_EXPENSES, {
    variables: {
      projectId: project._id,
    },
  });

  const [removeProjectExpense] = useMutation(REMOVE_PROJECT_EXPENSE);
  const [deductExpenses] = useMutation(DEDUCT_EXPENSES);

  const expenses = data?.projectManagement?.project?.expenses || [];

  return (
    <Card
      actionComponent={
        config['allow-deducting-expenses-from-project'] === '1' && (
          <div className="flex gap-2">
            <div className="flex items-center">
              <Tooltip title="Should these expenses be included in the financial calculations?">
                <Checkbox
                  defaultChecked={project.deductExpenses}
                  onChange={e => {
                    const value = e.target.checked;
                    deductExpenses({ variables: { projectId: project._id, value } });
                  }}
                >
                  Deduct from budget
                </Checkbox>
              </Tooltip>
            </div>
            <Button
              onClick={() => {
                setShowModal(true);
                setSelectedExpense({});
              }}
            >
              Add New
            </Button>
          </div>
        )
      }
    >
      <Modal footer={null} onCancel={() => setShowModal(false)} visible={showModal}>
        <ProjectExpenseForm
          setShowModal={setShowModal}
          selectedExpense={selectedExpense}
          projectId={project._id}
        />
      </Modal>
      <Table
        size="small"
        pagination={false}
        dataSource={expenses.map(expense => ({ ...expense, key: expense._id }))}
        loading={loading}
        columns={[
          {
            title: 'Name',
            key: 'name',
            width: 200,
            render: ({ name }) => <div>{name}</div>,
          },
          {
            title: 'Amount',
            key: 'amount',
            width: 150,
            render: ({ amount }) => <div>{numeral(amount).format('$ 0,0.00')}</div>,
          },
          {
            title: 'Comment',
            key: 'comment',
            render: ({ comment }) => <div>{comment}</div>,
          },
          {
            title: 'Actions',
            key: 'actions',
            render: expense => (
              <ProjectExpenseAction
                onEdit={() => {
                  setSelectedExpense(expense);
                  setShowModal(true);
                }}
                removeProjectExpense={removeProjectExpense}
                projectId={project._id}
                expenseId={expense._id}
              />
            ),
          },
        ]}
      />
    </Card>
  );
};

export default ProjectExpenses;
