import { useGlobal } from 'reactn';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Select, Input, InputNumber, Divider, Checkbox, DatePicker } from 'antd';
import Form from '../Form';
import Button from '../Button';
import TextLoader from '../TextLoader';
import { UPDATE_PROJECT } from './mutations';
import { TextArea } from '../Input';
import { GET_CLIENTS } from '../../../admin/queries';
import useTaxonomy from '../../../../hooks/useTaxonomy'; // Correct the import path
import moment from 'moment';

const GET_USERS = gql`
  query {
    users {
      _id
      firstName
      lastName
      emails {
        address
      }
    }
  }
`;

const { Option } = Select;

const UpdateProjectForm = ({ project, onComplete, refetchQueries = [] }) => {
  const [lockEditing] = useGlobal('lockEditing');
  const [updateProject] = useMutation(UPDATE_PROJECT, { refetchQueries });
  const { loading: loadingClients, data: clientQueryData } = useQuery(GET_CLIENTS);
  const { loading: loadingUsers, data: usersQuery } = useQuery(GET_USERS);
  const { loading: loadingSectors, data: sectorsQuery } = useTaxonomy('Sectors'); // Use the custom hook for sectors
  const { loading: loadingStatus, data: statusQuery } = useTaxonomy('Status'); // Use the custom hook for status
  const clients = loadingClients ? [] : clientQueryData?.projectManagement?.clients || [];
  const sectors = sectorsQuery?.taxonomy?.terms || [];
  const statuses = statusQuery?.taxonomy?.terms || [];
  const users = usersQuery?.users || [];

  if (loadingSectors || loadingClients || loadingStatus) {
    return <TextLoader text="Fetching sectors, clients, and statuses" />;
  }
  return (
    <Formik
      initialValues={{
        ...project,
        jobName: project.quickbooks?.jobName,
        clientId: project.client._id,
      }}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const {
          projectCode,
          name,
          description,
          sectors,
          accountingCode,
          accountingCodeSuffix,
          budget,
          jobName,
          clientId,
          globallyVisible,
          status,
          owner,
          totalBilledAmount,
          startDate,
        } = values;

        setTimeout(async () => {
          await updateProject({
            variables: {
              projectId: project._id,
              projectCode,
              name,
              description,
              sectors,
              accountingCode,
              accountingCodeSuffix,
              clientId,
              budget,
              jobName,
              globallyVisible,
              status,
              owner,
              totalBilledAmount,
              startDate,
            },
          });
          onComplete();
          setSubmitting(false);
        }, 1000);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Item
              label="Project Code"
              validateStatus={errors.projectCode && touched.projectCode && 'error'}
              help={
                (errors.projectCode && touched.projectCode && errors.projectCode) ||
                'Provide an optional project code'
              }
              hasFeedback
            >
              <Input
                name="projectCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.projectCode}
                disabled={isSubmitting || lockEditing}
              />
            </Form.Item>
            <Form.Item
              label="Project Name"
              validateStatus={errors.name && touched.name && 'error'}
              help={errors.name && touched.name && errors.name}
              hasFeedback
              required
            >
              <Input
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                disabled={isSubmitting}
              />
            </Form.Item>
            <Form.Item label="Start Date">
              <DatePicker
                name="startDate"
                onChange={momentDate => {
                  setFieldValue('startDate', momentDate.toDate());
                }}
                onBlur={handleBlur}
                value={values.startDate ? moment(values.startDate) : null}
                // disabled={isSubmitting || lockEditing}
              />
            </Form.Item>
            <Form.Item label="Project Owner">
              <Select
                allowClear
                disabled={isSubmitting || lockEditing}
                loading={loadingUsers}
                placeholder="Select a user"
                defaultValue={values.owner}
                onChange={value => {
                  setFieldValue('owner', value);
                }}
                showSearch
                onBlur={handleBlur}
              >
                {users
                  .map(user => `${user?.firstName} ${user?.lastName}`)
                  .reduce((acc, user) => {
                    if (!acc.find(u => u === user)) {
                      acc.push(user);
                    }
                    return acc;
                  }, [])
                  .sort((a, b) => a.localeCompare(b))
                  .map(user => (
                    <Option key={user} value={user}>
                      {user}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Description"
              validateStatus={errors.description && touched.description && 'error'}
              help={errors.description && touched.description && errors.description}
              hasFeedback
            >
              <TextArea
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                disabled={isSubmitting || lockEditing}
              />
            </Form.Item>
            <Form.Item label="Client">
              <Select
                disabled={isSubmitting}
                allowClear
                loading={loadingClients}
                placeholder="Select an existing client"
                defaultValue={project.client._id}
                onChange={value => {
                  setFieldValue('clientId', value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onBlur={handleBlur}
              >
                {clients.map(client => (
                  <Option key={client._id} value={client._id}>
                    {client.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Industry"
              disabled={isSubmitting || lockEditing}
              validateStatus={errors.sectors && touched.sectors && 'error'}
              help={
                (errors.sectors && touched.sectors && errors.sectors) ||
                'Select at least one industry'
              }
              hasFeedback
            >
              <Select
                mode="multiple"
                placeholder="Select industries"
                defaultValue={project.sectors || []}
                onChange={selectedSectors => {
                  setFieldValue('sectors', selectedSectors);
                }}
                disabled={isSubmitting || lockEditing}
              >
                {sectors.map(sector => (
                  <Option key={sector.name}>{sector.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Divider>Accounting</Divider>
            <Form.Item
              label="Budget"
              validateStatus={errors.budget && touched.budget && 'error'}
              help={(errors.budget && touched.budget && errors.budget) || 'Specify project budget'}
              hasFeedback
            >
              <InputNumber
                className="w-full"
                name="budget"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={value => {
                  setFieldValue('budget', value || 0);
                }}
                onBlur={handleBlur}
                defaultValue={values.budget}
                disabled={isSubmitting}
              />
            </Form.Item>
            <Form.Item
              label="Total Billed Amount"
              validateStatus={errors.totalBilledAmount && touched.totalBilledAmount && 'error'}
              help={
                (errors.totalBilledAmount &&
                  touched.totalBilledAmount &&
                  errors.totalBilledAmount) ||
                'Specify total billed amount'
              }
              hasFeedback
            >
              <InputNumber
                className="w-full"
                name="totalBilledAmount"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={value => {
                  setFieldValue('totalBilledAmount', value || 0);
                }}
                onBlur={handleBlur}
                defaultValue={values.totalBilledAmount}
                disabled={isSubmitting || lockEditing}
              />
            </Form.Item>
            <div className="flex flex-wrap sm:flex-no-wrap gap-2">
              <Form.Item
                className="w-full sm:w-1/2"
                label="Accounting Code"
                validateStatus={errors.accountingCode && touched.accountingCode && 'error'}
                help={
                  (errors.accountingCode && touched.accountingCode && errors.accountingCode) ||
                  'Optional accounting code'
                }
                hasFeedback
              >
                <Input
                  name="accountingCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.accountingCode}
                  disabled={isSubmitting || lockEditing}
                />
              </Form.Item>
              <Form.Item
                className="w-full sm:w-1/2"
                label="Accounting Code Suffix"
                validateStatus={
                  errors.accountingCodeSuffix && touched.accountingCodeSuffix && 'error'
                }
                help={
                  (errors.accountingCodeSuffix &&
                    touched.accountingCodeSuffix &&
                    errors.accountingCodeSuffix) ||
                  'Optional accounting code suffix'
                }
                hasFeedback
              >
                <Input
                  name="accountingCodeSuffix"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.accountingCodeSuffix}
                  disabled={isSubmitting || lockEditing}
                />
              </Form.Item>
            </div>
            <Form.Item help="Should this project be visible to all users on the timesheet entry screen?">
              <Checkbox
                name="globallyVisible"
                checked={values.globallyVisible}
                onChange={handleChange}
              >
                Show project to all users?
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="Status"
              validateStatus={errors.status && touched.status && 'error'}
              help={errors.status && touched.status && errors.status}
              hasFeedback
            >
              <Select
                placeholder="Select status"
                defaultValue={values.status || ''}
                onChange={selectedStatus => {
                  setFieldValue('status', selectedStatus);
                }}
                disabled={isSubmitting || lockEditing}
              >
                {statuses.map(status => (
                  <Option key={status.name}>{status.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Divider>Quickbooks</Divider>
            <Form.Item
              className="w-full"
              label="Quickbooks Job Name"
              validateStatus={errors.jobName && touched.jobName && 'error'}
              help={
                (errors.jobName && touched.jobName && errors.jobName) ||
                'Optional quickbooks job name'
              }
              hasFeedback
            >
              <Input
                name="jobName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.jobName}
                disabled={isSubmitting}
              />
            </Form.Item>
            <div className="flex justify-end">
              <Button loading={isSubmitting} type="primary" htmlType="submit">
                Update
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateProjectForm;
