import React from 'react';
import { CalendarGrayedRangesProps } from './types';

const CalendarGrayedRanges: React.FC<CalendarGrayedRangesProps> = ({
  ranges,
  timeToPixels,
  dayStart = 0,
  dayEnd = 24,
}) => {
  return (
    <>
      {ranges.map((range, index) => {
        // Adjust the range to be relative to dayStart
        const adjustedStart = Math.max(range.start, dayStart);
        const adjustedEnd = Math.min(range.end, dayEnd);
        
        // Only render if the range is visible within the current view
        if (adjustedStart >= adjustedEnd) return null;

        return (
          <div
            key={index}
            className="transition-all duration-100 ease-in-out"
            style={{
              position: 'absolute',
              height: `${timeToPixels(adjustedEnd - adjustedStart)}px`,
              top: `${timeToPixels(adjustedStart - dayStart)}px`,
              width: '100%',
              backgroundColor: '#f2f2f2',
              opacity: 0.5,
              zIndex: 1,
            }}
          />
        );
      })}
    </>
  );
};

export default CalendarGrayedRanges; 