import React from 'react';
import { Task, CalendarStackedTasksProps } from './types';
import CalendarTask from './CalendarTask';
import { assignPositions } from './utils';
import classNames from 'classnames';

const CalendarStackedTasks: React.FC<CalendarStackedTasksProps> = ({
  tasks,
  pixelsPerHour,
  onMouseDown,
  onDelete,
  onUpdate,
  useTimeBasedPositioning = false,
  dayStart = 0,
  dayEnd = 24,
  onEditModalOpenChange,
  isLocked = false,
  onEventClick,
}) => {
  // Get the positions array for side-by-side layout of overlapping tasks
  const taskPositions = assignPositions(tasks);
  
  // For each task, we need to know which position column it belongs to
  // and how many total positions are needed
  const taskToPositionMap = new Map<Task, { position: number, totalPositions: number }>();
  
  // Fill the map with position index and total positions for each task
  taskPositions.forEach((column, columnIndex) => {
    column.forEach(task => {
      // Find all tasks that overlap with this one
      const overlappingTasks = tasks.filter(t => 
        t !== task && // don't include self
        t.startTime < task.endTime && t.endTime > task.startTime
      );
      
      // If there are no overlapping tasks or not in time-based mode,
      // the task should take full width
      if (overlappingTasks.length === 0 || !useTimeBasedPositioning) {
        taskToPositionMap.set(task, {
          position: 0,
          totalPositions: 1
        });
      } else {
        // For overlapping tasks, use the column index and total number of columns
        taskToPositionMap.set(task, {
          position: columnIndex,
          totalPositions: taskPositions.length
        });
      }
    });
  });

  // Sort tasks by start time when using time-based positioning
  const sortedTasks = useTimeBasedPositioning
    ? [...tasks].sort((a, b) => a.startTime - b.startTime)
    : tasks;

  return (
    <div 
      className={classNames('relative', {
        'h-full': useTimeBasedPositioning,
      })}
      style={{
        height: useTimeBasedPositioning ? `${(dayEnd - dayStart) * pixelsPerHour}px` : 'auto',
      }}
    >
      {sortedTasks.map((task, i) => {
        const positionData = taskToPositionMap.get(task) || { position: 0, totalPositions: 1 };
        
        return (
          <CalendarTask
            key={i}
            task={task}
            pixelsPerHour={pixelsPerHour}
            position={positionData.position}
            totalPositions={positionData.totalPositions}
            onMouseDown={onMouseDown}
            onDelete={onDelete}
            onUpdate={onUpdate}
            useTimeBasedPositioning={useTimeBasedPositioning}
            dayStart={dayStart}
            dayEnd={dayEnd}
            onEditModalOpenChange={onEditModalOpenChange || (() => {})}
            isLocked={isLocked}
            onEventClick={onEventClick}
          />
        );
      })}
    </div>
  );
};

export default CalendarStackedTasks; 