import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import numeral from 'numeral';
import { Select, Checkbox, Input, InputNumber } from 'antd';
import { Formik } from 'formik';
import Table from '../../common/components/Table';
import Card from '../../common/components/Card';
import Form from '../../common/components/Form';
import Button from '../../common/components/Button';
import Popconfirm from '../../common/components/Popconfirm';
import Modal from '../../common/components/Modal';

const { Option } = Select;

const GET_AVAILABLE_ROLES = gql`
  query getRoles {
    taxonomy(name: "Roles") {
      _id
      name
      terms {
        _id
        name
      }
    }
  }
`;

export const GET_PROJECT_ROLES = gql`
  query getProjectRoles($projectId: String!) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        roles {
          _id
          name
          rate
        }
      }
    }
  }
`;

const UPDATE_PROJECT_ROLE = gql`
  mutation updateProjectRole($projectId: ID!, $indexId: ID!, $rate: Float!, $name: String!) {
    updateProjectRole(projectId: $projectId, indexId: $indexId, input: { name: $name, rate: $rate })
  }
`;

const ADD_PROJECT_ROLE = gql`
  mutation addProjectRole($projectId: ID!, $rate: Float!, $name: String!) {
    addProjectRole(projectId: $projectId, input: { name: $name, rate: $rate })
  }
`;

const REMOVE_PROJECT_ROLE = gql`
  mutation removeProjectRole($projectId: ID!, $indexId: ID!) {
    removeProjectRole(projectId: $projectId, indexId: $indexId)
  }
`;

const ProjectRoleForm = ({ projectId, existingRoles = [], role = {}, setShowModal }) => {
  const [createNew, setCreateNew] = useState(false);
  const [updateProjectRole] = useMutation(UPDATE_PROJECT_ROLE);
  const [addProjectRole] = useMutation(ADD_PROJECT_ROLE);
  const { loading, data } = useQuery(GET_AVAILABLE_ROLES);
  const availableRoles = data?.taxonomy?.terms || [];
  const { _id: indexId } = role;
  return (
    <Formik
      key={role._id}
      initialValues={role}
      validate={values => {
        const errors = {};
        if (values.rate !== 0 && !values.rate) {
          errors.rate = 'Hourly rate is required';
        }
        if (values.rate < 0) {
          errors.rate = 'Provide a positive value';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        if (role._id) {
          await updateProjectRole({
            variables: { projectId, indexId, ...values },
            refetchQueries: [
              {
                query: GET_PROJECT_ROLES,
                variables: {
                  projectId,
                },
              },
            ],
            awaitRefetchQueries: true,
          });
        } else {
          await addProjectRole({
            variables: { projectId, ...values },
            refetchQueries: [
              {
                query: GET_PROJECT_ROLES,
                variables: {
                  projectId,
                  ...values,
                },
              },
            ],
            awaitRefetchQueries: true,
          });
          resetForm();
        }
        setSubmitting(false);
        setShowModal(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        setValues,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
        resetForm,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <Form.Item
              label="Available Roles"
              hasFeedback
              validateStatus={errors.name && touched.name && 'error'}
              help=""
            >
              <Select
                showSearch
                allowClear
                loading={loading}
                placeholder="Select a role"
                value={createNew ? null : values.name}
                disabled={isSubmitting || createNew}
                onChange={value => {
                  if (value) {
                    setFieldValue('name', value);
                  } else {
                    setFieldValue('name', null);
                  }
                }}
                onBlur={handleBlur}
              >
                {availableRoles.map(role => (
                  <Option
                    key={role._id}
                    value={role.name}
                    disabled={existingRoles.includes(role.name)}
                  >
                    {role.name}
                  </Option>
                ))}
              </Select>
              <Checkbox checked={createNew} onChange={e => setCreateNew(e.target.checked)}>
                Custom role?
              </Checkbox>
            </Form.Item>
            {createNew && (
              <Form.Item
                label="Custom Role"
                validateStatus={errors.name ? 'error' : null}
                hasFeedback={!!errors.name}
                help={errors.name ? errors.name : null}
              >
                <Input
                  onChange={e => setValues({ ...values, name: e.target.value })}
                  value={values.name}
                  disabled={isSubmitting}
                  name="name"
                />
              </Form.Item>
            )}
            <Form.Item
              label="Rate"
              validateStatus={errors.rate ? 'error' : null}
              hasFeedback={!!errors.rate}
              help={errors.rate ? errors.rate : null}
            >
              <InputNumber
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={rate => setValues({ ...values, rate })}
                value={values.rate}
                disabled={isSubmitting}
                name="rate"
              />
            </Form.Item>
          </div>
          <div className="flex justify-end pr-3">
            {dirty && (
              <>
                <Form.Item>
                  <Button
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const ProjectRoleAction = ({ removeProjectRole, onEdit, projectId, indexId }) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [lockEditing] = useGlobal();
  return (
    <div className="flex flex-start">
      <Button
        // disabled={lockEditing}
        className="mr-2"
        onClick={() => onEdit()}
      >
        Edit
      </Button>
      <Popconfirm
        placement="topLeft"
        title="Are you sure you want to delete this role?"
        onConfirm={async () => {
          setIsRemoving(true);
          await removeProjectRole({
            variables: { projectId, indexId },
            refetchQueries: [
              {
                query: GET_PROJECT_ROLES,
                variables: {
                  projectId,
                },
              },
            ],
            awaitRefetchQueries: true,
          });
          setIsRemoving(false);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button disabled={lockEditing} type="danger" loading={isRemoving} icon="close"></Button>
      </Popconfirm>
    </div>
  );
};

const ProjectRoles = ({ projectId }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});
  const { loading, data } = useQuery(GET_PROJECT_ROLES, {
    variables: {
      projectId,
    },
  });

  const [removeProjectRole] = useMutation(REMOVE_PROJECT_ROLE);

  const roles = data?.projectManagement?.project?.roles || [];

  return (
    <Card
      actionComponent={
        <Button
          // disabled={lockEditing}
          onClick={() => {
            setShowModal(true);
            setSelectedRole({});
          }}
        >
          Add New Role
        </Button>
      }
    >
      <Modal footer={null} onCancel={() => setShowModal(false)} visible={showModal}>
        <ProjectRoleForm
          setShowModal={setShowModal}
          existingRoles={roles.map(r => r.name)}
          role={selectedRole}
          projectId={projectId}
        />
      </Modal>
      <Table
        size="small"
        pagination={false}
        dataSource={roles.map(role => ({ ...role, key: role._id }))}
        loading={loading}
        columns={[
          {
            title: 'Role Name',
            key: 'name',
            render: ({ name }) => <div>{name}</div>,
          },
          {
            title: 'Rate',
            key: 'rate',
            render: ({ rate }) => <div>{numeral(rate).format('$ 0,0.00')}</div>,
          },
          {
            title: 'Actions',
            key: 'actions',
            render: role => (
              <ProjectRoleAction
                onEdit={() => {
                  setSelectedRole(role);
                  setShowModal(true);
                }}
                removeProjectRole={removeProjectRole}
                projectId={projectId}
                indexId={role._id}
              />
            ),
          },
        ]}
      />
    </Card>
  );
};

export default ProjectRoles;
