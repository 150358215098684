import { useState } from 'react';
import { withApollo } from 'react-apollo';
import { useGlobal, getGlobal } from 'reactn';
import { Formik } from 'formik';
import moment from 'moment';
import gql from 'graphql-tag';
import { Select, Checkbox, InputNumber } from 'antd';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';
import DatePicker from '../DatePicker';
import { UPDATE_DELIVERABLE, ADD_DELIVERABLE } from './mutation';
import { showError, showSuccess } from '../../notifications';
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const dateFormat = 'M/D/YYYY';

const REMOVE_PROJECT_DELIVERABLE = gql`
  mutation removeDeliverable($deliverableId: ID!) {
    removeDeliverable(deliverableId: $deliverableId)
  }
`;

export const updateDeliverable = ({
  values,
  setSubmitting,
  deliverable,
  isMilestone,
  client,
  refetchQueries,
  onComplete,
  usePercentage,
}) => {
  if (deliverable._id) {
    if (isMilestone && !values.budget && !values.budgetPercent) {
      client
        .mutate({
          mutation: REMOVE_PROJECT_DELIVERABLE,
          variables: {
            deliverableId: deliverable._id,
          },
          awaitRefetchQueries: true,
          refetchQueries,
        })
        .then(() => {
          setSubmitting(false);
          onComplete();
          // showSuccess({ message: `Successfully updated deliverable.` });
        })
        .catch(err => {
          // console.log('Error Message: ', err.message);
          showError({ message: err.message });
        });
    } else {
      return client
        .mutate({
          mutation: UPDATE_DELIVERABLE,
          variables: {
            id: deliverable._id,
            ...values,
            disabled: values.disabled ? values.disabled : false,
            trackAgainstTimeOff: values.trackAgainstTimeOff ? values.trackAgainstTimeOff : null,
            rate: values.rate,
            budgetedHours: values.budgetedHours ? values.budgetedHours : null,
            budget: !usePercentage && values.budget ? Number(values.budget) : null,
            budgetPercent:
              usePercentage && values.budgetPercent ? Number(values.budgetPercent) : null,
            startDate: values.startDate,
            endDate: values.endDate,
            billingDate: values.billingDate,
            status: values.status,
          },
          awaitRefetchQueries: true,
          refetchQueries,
        })
        .then(res => {
          if (res?.errors) {
            showError({ message: res.errors[0].message });
          } else {
            showSuccess({ message: `Successfully updated deliverable.` });
          }
          setSubmitting(false);
          onComplete();
        })
        .catch(err => {
          // console.log('Error Message: ', err.message);
          showError({ message: err.message });
        });
    }
  } else {
    return client
      .mutate({
        mutation: ADD_DELIVERABLE,
        variables: {
          projectId: deliverable.projectId,
          ...values,
          rate: values.rate ? values.rate : null,
          budgetedHours: values.budgetedHours ? values.budgetedHours : null,
          budget: !usePercentage && values.budget ? Number(values.budget) : null,
          budgetPercent:
            usePercentage && values.budgetPercent ? Number(values.budgetPercent) : null,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      })
      .then(() => {
        setSubmitting(false);
        onComplete();
        showSuccess({ message: `Successfully updated deliverable.` });
      })
      .catch(err => {
        // console.log('Error Message: ', err.message);
        showError({ message: err.message });
      });
  }
};

const UpdateDeliverableForm = ({
  onComplete,
  client,
  deliverable = {},
  refetchQueries = [],
  milestoneNames,
  isMilestone,
  milestoneBudget,
  disabled,
  trackAgainstTimeOff,
  timeOffTaxonomy,
}) => {
  const { config } = getGlobal();
  const netsuiteUrl = config['netsuite-url'];
  const hoursBudgetingEnabled = config['hours-budgeting-deliverable'] === 'true';
  const showTimeOffBalances = config['show-timeoff-balances'] === '1';
  const [user] = useGlobal('user');
  const [createNew, setCreateNew] = useState(false);
  const [usePercentage, setUsePercentage] = useState(deliverable.budgetPercent > 0);
  const financeGroupInc = user.groups.includes('finance');
  const timeOffList = timeOffTaxonomy?.data?.taxonomy?.terms;

  return (
    <Formik
      initialValues={{
        ...deliverable,
        deliverableName: isMilestone ? deliverable.milestoneName : deliverable.deliverableName,
      }}
      validate={values => {
        const errors = {};
        if (netsuiteUrl && !values.startDate && !isMilestone) {
          errors['startDate'] = 'Start Date is required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await updateDeliverable({
          values,
          setSubmitting,
          deliverable,
          isMilestone,
          client,
          refetchQueries,
          onComplete,
          usePercentage,
          disabled,
          trackAgainstTimeOff,
        });
      }}
    >
      {({ values, setFieldValue, handleChange, handleSubmit, errors, isSubmitting, isValid }) => {
        const {
          deliverableName,
          milestoneName,
          budget,
          budgetPercent,
          rate,
          accountingCodeSuffix,
          accountingCode,
          startDate,
          endDate,
          billingDate,
          status,
          trackAgainstTimeOff,
          budgetedHours,
        } = values;

        return (
          <Form onSubmit={handleSubmit} {...formItemLayout}>
            {!isMilestone && (
              <>
                <Form.Item label="Category" hasFeedback>
                  <Select
                    disabled={createNew}
                    name="cagegory"
                    showSearch
                    defaultValue={milestoneName}
                    placeholder="Select category"
                    optionFilterProp="children"
                    onChange={category => {
                      setFieldValue('milestoneName', category);
                    }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {milestoneNames &&
                      milestoneNames.map(milestoneName => {
                        return (
                          <Option value={milestoneName} key={milestoneName}>
                            {milestoneName}
                          </Option>
                        );
                      })}
                  </Select>
                  <Checkbox checked={createNew} onChange={e => setCreateNew(e.target.checked)}>
                    Create New
                  </Checkbox>
                </Form.Item>
                {createNew && (
                  <Form.Item label="New Category" hasFeedback help="Specify new category">
                    <Input name="milestoneName" onChange={handleChange} value={milestoneName} />
                  </Form.Item>
                )}
                <Form.Item label="Deliverable" hasFeedback>
                  <Input name="deliverableName" onChange={handleChange} value={deliverableName} />
                </Form.Item>
                <Form.Item
                  label="Rate"
                  hasFeedback
                  help={'Specify a rate to override the role rate for this task.'}
                >
                  <InputNumber
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={rate => setFieldValue('rate', rate)}
                    name="rate"
                    value={rate}
                  />
                </Form.Item>
                <Form.Item
                  label="Start Date"
                  hasFeedback
                  validateStatus={errors.startDate ? 'error' : null}
                  required={netsuiteUrl}
                >
                  <DatePicker
                    onChange={momentDate => {
                      const dateWithCurrentTime = moment(
                        momentDate.format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss'),
                      );
                      setFieldValue('startDate', dateWithCurrentTime.toDate());
                    }}
                    value={startDate ? moment(startDate) : null}
                    format={dateFormat}
                  />
                </Form.Item>
                <Form.Item label="End Date" hasFeedback>
                  <DatePicker
                    onChange={momentDate => {
                      if (momentDate) {
                        const dateWithCurrentTime = moment(
                          momentDate.format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss'),
                        );
                        setFieldValue('endDate', dateWithCurrentTime.toDate());
                      } else {
                        setFieldValue('endDate', null);
                      }
                    }}
                    value={endDate ? moment(endDate) : null}
                    format={dateFormat}
                  />
                </Form.Item>
                <Form.Item label="Billing Date" hasFeedback>
                  <DatePicker
                    onChange={momentDate => {
                      if (momentDate) {
                        const dateWithCurrentTime = moment(
                          momentDate.format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss'),
                        );
                        setFieldValue('billingDate', dateWithCurrentTime.toDate());
                      } else {
                        setFieldValue('billingDate', null);
                      }
                    }}
                    value={billingDate ? moment(billingDate) : null}
                    format={dateFormat}
                  />
                </Form.Item>
                <Form.Item label="Status" hasFeedback>
                  <Select
                    name="status"
                    showSearch
                    allowClear
                    placeholder="Select a status"
                    value={status}
                    onChange={status => {
                      setFieldValue('status', status);
                    }}
                  >
                    <Option value="Not Started" key="Not Started">
                      Not Started
                    </Option>
                    <Option value="In Progress" key="In Progress">
                      In Progress
                    </Option>
                    <Option value="Completed" key="Completed">
                      Completed
                    </Option>
                  </Select>
                </Form.Item>
                {hoursBudgetingEnabled && (
                  <Form.Item
                    label="Budgeted Hours"
                    help="How many hours are you budgeting for this deliverable?"
                  >
                    <InputNumber
                      onChange={value => setFieldValue('budgetedHours', value)}
                      value={budgetedHours}
                    />
                  </Form.Item>
                )}
              </>
            )}
            <Form.Item
              label="Budget"
              hasFeedback
              help={milestoneBudget && 'Budget has been set at the category level.'}
            >
              <Input
                onChange={e => {
                  if (usePercentage) {
                    setFieldValue('budgetPercent', e.target.value);
                    setFieldValue('budget', null);
                  } else {
                    setFieldValue('budgetPercent', null);
                    setFieldValue('budget', e.target.value);
                  }
                }}
                name="budget"
                addonAfter={
                  <Select
                    defaultValue={usePercentage ? '%' : 'USD'}
                    onChange={value => {
                      if (value === 'percent') {
                        setUsePercentage(true);
                      } else {
                        setUsePercentage(false);
                      }
                    }}
                    style={{ width: 60 }}
                  >
                    <Option value="percent">%</Option>
                    <Option value="USD">$</Option>
                  </Select>
                }
                disabled={!!milestoneBudget}
                value={usePercentage ? budgetPercent : budget}
              />
            </Form.Item>
            {!isMilestone && (
              <Form.Item label="Disabled" hasFeedback>
                <Checkbox
                  defaultChecked={disabled}
                  onChange={e => {
                    setFieldValue('disabled', e.target.checked);
                  }}
                >
                  Disable this deliverable?
                </Checkbox>
              </Form.Item>
            )}
            {financeGroupInc && (
              <>
                <Form.Item label="BU Code" hasFeedback>
                  <Input name="accountingCode" onChange={handleChange} value={accountingCode} />
                </Form.Item>
                <Form.Item label="IS Code" hasFeedback>
                  <Input
                    name="accountingCodeSuffix"
                    onChange={handleChange}
                    value={accountingCodeSuffix}
                  />
                </Form.Item>
              </>
            )}
            {!isMilestone && showTimeOffBalances && user?.groups?.includes('human-resources') && (
              <Form.Item label="Track Against Time Off" hasFeedback>
                <Select
                  name="timeOff"
                  showSearch
                  allowClear
                  placeholder="Select a type of time off"
                  value={trackAgainstTimeOff}
                  onChange={trackAgainstTimeOff => {
                    setFieldValue('trackAgainstTimeOff', trackAgainstTimeOff);
                  }}
                >
                  {timeOffList?.map(option => (
                    <Select.Option key={option.name} value={option.name}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <div className="flex justify-end">
              <Button
                disabled={!isMilestone && !isValid}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                Update Deliverable
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withApollo(UpdateDeliverableForm);
