import React, { useState } from 'react';
import { getGlobal } from 'reactn';
import numeral from 'numeral';
import { Button, Text, TextInput, Tooltip, Table } from '@mantine/core';
import { Button as AntButton, Popconfirm, Empty } from 'antd';
import { modals } from '@mantine/modals';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Input from '../../common/components/Input';
import client from '../../../api/graphql/client';

const GET_RESOURCES_BY_DELIVERABLE_ID = gql`
  query getResourcesByDeliverableId($deliverableId: String!) {
    projectManagement {
      deliverable(deliverableId: $deliverableId) {
        _id
        assignedResources {
          budgetedHours
          resource {
            _id
            name
            email
          }
        }
      }
    }
  }
`;

const ADD_DELIVERABLE_RESOURCE = gql`
    mutation addDeliverableResource($deliverableId: ID!, $input: DeliverableResourceInput!) {
        addDeliverableResource(deliverableId: $deliverableId, input: $input)
    }
`;

const REMOVE_DELIVERABLE_RESOURCE = gql`
    mutation removeDeliverableResource($deliverableId: ID!, $resourceId: ID!) {
        removeDeliverableResource(deliverableId: $deliverableId, resourceId: $resourceId)
    }
`;

const UPDATE_DELIVERABLE_RESOURCE = gql`
    mutation updateDeliverableResource($deliverableId: ID!, $input: DeliverableResourceInput!) {
        updateDeliverableResource(deliverableId: $deliverableId, input: $input)
    }
`;

interface ProjectDeliverableResourceAssignmentsProps {
    deliverable: any;
    refetchQueries: any[];
    resources: any[];
}

interface ResourceAssignmentRowProps {
    resourceAssignment: any;
    deliverableId: string;
    refetchQueries: any[];
}

const ResourceAssignmentRow: React.FC<ResourceAssignmentRowProps> = ({
    resourceAssignment,
    deliverableId,
    refetchQueries
}) => {
    const [budgetedHours, setBudgetedHours] = useState(resourceAssignment.budgetedHours);
    const [isEditing, setIsEditing] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const handleUpdate = () => {
        setIsEditing(true);
        client.mutate({
            mutation: UPDATE_DELIVERABLE_RESOURCE,
            variables: {
                deliverableId,
                input: {
                    resourceId: resourceAssignment.resource._id,
                    budgetedHours: Number(budgetedHours)
                }
            },
            refetchQueries,
            awaitRefetchQueries: true
        }).then(() => {
            setIsEditing(false);
        });
    };

    const handleRemove = async () => {
        setIsRemoving(true);
        try {
            const result = await client.mutate({
                mutation: REMOVE_DELIVERABLE_RESOURCE,
                variables: {
                    deliverableId,
                    resourceId: resourceAssignment.resource._id
                },
                refetchQueries,
                awaitRefetchQueries: true
            });

            if (result.errors) {
                console.error('Failed to remove resource:', result.errors[0].message);
            }
        } catch (error) {
            console.error('Failed to remove resource:', error);
        } finally {
            setIsRemoving(false);
        }
    };

    return (
        <tr>
            <td>{resourceAssignment.resource.name}</td>
            <td>{resourceAssignment.resource.email}</td>
            <td>
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value === '') {
                            setBudgetedHours(0);
                        } else {
                            setBudgetedHours(Number(e.target.value));
                        }
                    }}
                    disabled={isEditing}
                    onBlur={handleUpdate}
                    onPressEnter={handleUpdate}
                    value={budgetedHours}
                    type="number"
                    step="0.25"
                />
                {/* <TextInput
                    type="number"
                    value={budgetedHours}
                    disabled={isEditing}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setIsEditing(true);
                        if (e.target.value === '') {
                            setBudgetedHours(0);
                        } else {
                            setBudgetedHours(Number(e.target.value));
                        }
                    }}
                    onBlur={handleUpdate}
                    placeholder="Hours"
                    w={100}
                /> */}
            </td>
            <td>
                <Popconfirm
                    placement="topLeft"
                    title="Are you sure you want to remove this resource from the deliverable?"
                    onConfirm={async () => {
                        setIsRemoving(true);
                        await handleRemove();
                        setIsRemoving(false);
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <AntButton
                        type="danger"
                        loading={isRemoving}
                        icon="close"
                    ></AntButton>
                </Popconfirm>
            </td>
        </tr>
    );
};

interface AddResourceListProps {
    resources: any[];
    deliverableId: string;
    refetchQueries: any[];
    onSuccess: () => void;
    assignedResourceIds: string[];
}

const AddResourceList: React.FC<AddResourceListProps> = ({
    resources,
    deliverableId,
    refetchQueries,
    onSuccess,
    assignedResourceIds = []
}) => {
    const [loadingResourceId, setLoadingResourceId] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleAddResource = async (resource: any) => {
        setLoadingResourceId(resource._id);
        try {
            await client.mutate({
                mutation: ADD_DELIVERABLE_RESOURCE,
                variables: {
                    deliverableId,
                    input: {
                        resourceId: resource._id,
                        budgetedHours: null
                    }
                },
                refetchQueries,
                awaitRefetchQueries: true
            });
            onSuccess();
        } catch (error) {
            console.error('Failed to add resource:', error);
        } finally {
            setLoadingResourceId(null);
        }
    };

    const filteredResources = resources.filter(resource =>
    (resource.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        resource.email?.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <div className="space-y-4">
            <TextInput
                placeholder="Search resources..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                mb="md"
            />
            <div className="space-y-2">
                {filteredResources.map((resource: any) => {
                    const isAssigned = Array.isArray(assignedResourceIds) && assignedResourceIds.includes(resource._id);
                    return (
                        <Button
                            key={resource._id}
                            variant="outline"
                            fullWidth
                            disabled={isAssigned || !!loadingResourceId}
                            loading={loadingResourceId === resource._id}
                            onClick={() => handleAddResource(resource)}
                        >
                            {resource.name} - {resource.email}
                            {isAssigned && " (Already assigned)"}
                        </Button>
                    );
                })}
                {filteredResources.length === 0 && (
                    <Text color="dimmed" align="center">No resources found</Text>
                )}
            </div>
        </div>
    );
};

const ResourceAssignmentTable = ({ deliverableId, refetchQueries }: { deliverableId: string, refetchQueries: any[] }) => {
    const { data, loading, error } = useQuery(GET_RESOURCES_BY_DELIVERABLE_ID, {
        variables: { deliverableId }
    });

    const deliverable = data?.projectManagement?.deliverable;

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading resources</div>;
    if (!deliverable) return null;

    const assignedResourceIds = deliverable.assignedResources?.map((ra: any) => ra.resource._id) || [];

    if (deliverable.assignedResources.length === 0) return <div>
        <Empty
            className="m-16"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
                height: 60,
            }}
            description={<span>No resources assigned to this deliverable.</span>}
        ></Empty>
    </div>;

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Budgeted Hours</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {deliverable.assignedResources.map((resourceAssignment: any, index: number) => (
                        <ResourceAssignmentRow
                            key={resourceAssignment.resource._id}
                            resourceAssignment={resourceAssignment}
                            deliverableId={deliverableId}
                            refetchQueries={refetchQueries}
                        />
                    ))}
                </tbody>
            </Table>
            <input type="hidden" id="assignedResourceIds" value={JSON.stringify(assignedResourceIds)} />
        </>
    );
};

const ProjectDeliverableResourceAssignments: React.FC<ProjectDeliverableResourceAssignmentsProps> = ({
    deliverable,
    refetchQueries,
    resources
}) => {
    // @ts-ignore
    const { config } = getGlobal();
    const netsuiteUrl = config['netsuite-url'];
    const updatedRefetchQueries = [...refetchQueries, {
        query: GET_RESOURCES_BY_DELIVERABLE_ID,
        variables: { deliverableId: deliverable._id }
    }];

    const openResourceManagementModal = () => {
        modals.openConfirmModal({
            title: 'Manage Resources',
            size: 'xl',
            closeOnConfirm: false,
            labels: { confirm: 'Add New Resource', cancel: 'Close' },
            children: (
                <ResourceAssignmentTable deliverableId={deliverable._id} refetchQueries={updatedRefetchQueries} />
            ),
            onConfirm: () => {
                const hiddenInput = document.getElementById('assignedResourceIds') as HTMLInputElement;
                const assignedResourceIds = hiddenInput?.value ? JSON.parse(hiddenInput.value) : [];

                modals.openConfirmModal({
                    title: 'Add New Resource',
                    size: 'md',
                    labels: { confirm: 'Add Resource', cancel: 'Back' },
                    children: (
                        <AddResourceList
                            resources={resources}
                            deliverableId={deliverable._id}
                            refetchQueries={updatedRefetchQueries}
                            assignedResourceIds={assignedResourceIds}
                            onSuccess={() => {
                                modals.closeAll();
                                openResourceManagementModal();
                            }}
                        />
                    ),
                });
            },
        });
    };

    return (
        <div className="flex items-center gap-2">
            <div>
                {deliverable?.assignedResources?.length > 0 ? (
                    <>
                        {deliverable?.assignedResources?.map((resourceAssignment: any) => {
                            return <div key={resourceAssignment.resourceId + resourceAssignment.resource.email}>
                                <Tooltip label={`${resourceAssignment.resource.user.firstName} ${resourceAssignment.resource.user.lastName} - ${resourceAssignment.resource.email}`}>
                                    <div>
                                        {resourceAssignment.resource.user.firstName} {resourceAssignment?.budgetedHours ? `(${numeral(resourceAssignment.budgetedHours).format('0.[00]')} hours)` : ''}
                                    </div>
                                </Tooltip>
                            </div>
                        })}
                    </>
                ) : (
                    'Not assigned'
                )}
            </div>
            {!netsuiteUrl && (
                <div>
                    <AntButton size="small" icon="plus" onClick={openResourceManagementModal} />
                </div>
            )}
        </div>
    );
};

export default ProjectDeliverableResourceAssignments;
