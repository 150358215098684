import { useState, useEffect } from 'react';
import { Task, CalendarDayProps } from './types';
import classNames from 'classnames';
import { calculateTimeConversions, calculateTotalHours } from './utils';
import CalendarTasks from './CalendarTasks';
import CalendarMarkers from './CalendarMarkers';
import CalendarGrayedRanges from './CalendarGrayedRanges';
import CalendarTimeTicks from './CalendarTimeTicks';

const CalendarDay = ({
  height = '500px',
  dayStart = 0,
  dayEnd = 24,
  date,
  tasks = [],
  grayedOutRanges = [
    {
      start: 0,
      end: 9,
    },
    {
      start: 18,
      end: 24,
    },
  ],
  markers = [
    {
      time: 8,
      label: '8 hours',
      lineColor: 'red',
      backgroundColor: 'rgba(255, 0, 0, 0.15)',
      condition: () => calculateTotalHours(tasks) > 8,
    },
  ],
  onNewTask = () => { },
  onDeleteTask = () => { },
  onUpdateTask = () => { },
  onTaskDragOver = () => { },
  onTaskDrop = () => { },
  onHeaderClick = () => { },
  useTimeBasedPositioning = false,
  isEditModalOpen = false,
  onEditModalOpenChange = () => { },
  isLocked = false,
  isExpanded = false,
  onEventClick = () => { },
}: CalendarDayProps) => {
  const heightInPx = parseInt(height, 10);
  const timeIncrement = 0.25;
  const { pixelsToTime, timeToPixels, pixelsPerHour } = calculateTimeConversions(
    heightInPx,
    dayStart,
    dayEnd
  );

  const [dragging, setDragging] = useState(false);
  const [newTask, setNewTask] = useState<Task | null>(null);
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [existingTasks, setExistingTasks] = useState<Task[]>(tasks);
  const [isHovering, setIsHovering] = useState(false);
  const [isAdjustingTask, setIsAdjustingTask] = useState(false);

  useEffect(() => {
    setExistingTasks(tasks);
  }, [tasks]);

  const handleNewTaskMouseDown = (e: React.MouseEvent) => {
    if (isEditModalOpen || isAdjustingTask || isLocked) {
      return;
    }

    const rect = e.currentTarget.getBoundingClientRect();
    const y = e.clientY - rect.top;
    const time = Math.round((pixelsToTime(y) + dayStart) / timeIncrement) * timeIncrement;

    const endTime = Math.round((pixelsToTime(y) + dayStart) / timeIncrement) * timeIncrement;

    setNewTask({
      startTime: time,
      endTime,
      rect,
      startY: y,
      title: '',
      color: '#3B82F6',
      date: date.format('YYYY-MM-DD'),
      data: {
        isOutlookEvent: false,
        eventId: '',
        location: ''
      }
    });
    setIsCreatingTask(true);
    setDragging(true);
  };

  const handleNewTaskMoveMove = (e: React.MouseEvent) => {
    if (isLocked) return;
    
    if (dragging && newTask && newTask.rect && newTask.startY !== undefined) {
      const y = e.clientY - newTask.rect.top;
      const minTime = Math.round((pixelsToTime(Math.min(newTask.startY, y)) + dayStart) / timeIncrement) * timeIncrement;
      const maxTime = Math.round((pixelsToTime(Math.max(newTask.startY, y)) + dayStart) / timeIncrement) * timeIncrement;

      setNewTask(prev => prev ? { ...prev, startTime: minTime, endTime: maxTime } : null);
    }
  };

  const handleNewTaskMouseUp = () => {
    if (isLocked) return;
    
    if (newTask && onNewTask) {
      onNewTask([...tasks, { ...newTask, date: date.format('YYYYMMDD') }]);
      setNewTask(null);
      setIsCreatingTask(false);
      setDragging(false);
    }
  };

  const handleEditTaskMouseDown = (e: React.MouseEvent, task: Task) => {
    if (isLocked) return;
    
    e.stopPropagation();
    setIsAdjustingTask(true);
    // Add block-specific edit behavior here
  };

  const handleDragOver = (e: React.DragEvent) => {
    if (isLocked) return;
    
    e.preventDefault();
    e.stopPropagation();
    if (onTaskDragOver) {
      onTaskDragOver(date.format('YYYYMMDD'));
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    if (isLocked) return;
    
    e.preventDefault();
    e.stopPropagation();
    if (onTaskDrop) {
      try {
        const taskData = JSON.parse(e.dataTransfer.getData('text/plain'));
        onTaskDrop(taskData, date.format('YYYYMMDD'));
      } catch (error) {
        console.error('Failed to parse dragged task data:', error);
      }
    }
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        setNewTask(null);
        setDragging(false);
        setIsAdjustingTask(false);
      }
    };

    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    const handleMouseUp = () => {
      if (isAdjustingTask) {
        setIsAdjustingTask(false);
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isAdjustingTask]);

  return (
    <div className="flex flex-col">
      <div
        className={
          classNames(
            'relative overflow-y-hidden bg-white w-full transition-all duration-100 ease-in-out'
          )
        }
        style={{
          height: height,
          userSelect: 'none'
        }}
        onMouseDown={handleNewTaskMouseDown}
        onMouseMove={handleNewTaskMoveMove}
        onMouseUp={handleNewTaskMouseUp}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {!useTimeBasedPositioning && (
          <CalendarMarkers markers={markers} timeToPixels={timeToPixels} />
        )}
        {useTimeBasedPositioning && (
          <>
            <CalendarGrayedRanges 
              ranges={grayedOutRanges} 
              timeToPixels={timeToPixels}
              dayStart={dayStart}
              dayEnd={dayEnd}
            />
            <CalendarTimeTicks
              dayStart={dayStart}
              dayEnd={dayEnd}
              height={heightInPx}
              isHovering={isHovering}
              isCreatingTask={dragging}
              isAdjustingTask={isAdjustingTask}
            />
          </>
        )}
        <div className='px-2 transition-all duration-100 ease-in-out'>
          <CalendarTasks
            tasks={existingTasks}
            pixelsPerHour={pixelsPerHour}
            onMouseDown={handleEditTaskMouseDown}
            onDelete={onDeleteTask}
            onUpdate={onUpdateTask}
            useTimeBasedPositioning={useTimeBasedPositioning}
            dayStart={dayStart}
            dayEnd={dayEnd}
            onEditModalOpenChange={(isOpen: boolean, task?: Task) => {
              onEditModalOpenChange(isOpen, task);
            }}
            isLocked={isLocked}
            onEventClick={onEventClick}
          />
        </div>

        {isCreatingTask && newTask && (
          <div
            style={{
              position: 'absolute',
              height: `${timeToPixels(newTask.endTime - newTask.startTime)}px`,
              top: `${timeToPixels(newTask.startTime - dayStart)}px`,
              width: '100%',
              zIndex: 3,
            }}
            className="bg-gray-200 opacity-75 flex justify-center items-center transition-all duration-100 ease-in-out"
          >
            {`${(newTask.endTime - newTask.startTime).toFixed(2)}hr`}
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarDay; 