import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import ProjectList from './ProjectList';
import { GET_MY_PROJECTS, GET_ALL_PROJECTS } from '../queries';

const ProjectListContainer = ({ history, match, showAllProjects = false }) => {
  const [filtersSet, setFiltersSet] = useState(false);
  const [searchQuery, setQuery] = useState(null);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState({});
  const [limit] = useState(50);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const filterFromUrl = params.get('filter') ? JSON.parse(params.get('filter')) : {
      status: 'In Progress',
    };
    const sortFromUrl = params.get('sort') ? JSON.parse(params.get('sort')) : {};
    const searchFromUrl = params.get('search') || null;

    setFilter(filterFromUrl);
    setSort(sortFromUrl);
    setQuery(searchFromUrl);
    setFiltersSet(true); // need this because search is not set in the input field on initial render
  }, [history.location.pathname, history.location.search]);

    return (
      <Query
        query={showAllProjects ? GET_ALL_PROJECTS : GET_MY_PROJECTS}
        variables={{ offset: 0, limit, search: searchQuery, ...filter, ...sort }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data, fetchMore }) => {
          if (error) throw new Error(error);

          const projects = showAllProjects ? get(data, 'projectManagement.allProjects') : get(data, 'projectManagement.myProjects');

          if (!filtersSet) {
            return null;
          }

          return (
            <ProjectList
              showFilters
              showSearch
              title={showAllProjects ? 'All Projects' : 'My Projects'}
              history={history}
              projects={projects}
              loading={loading}
              onSearch={query => {
                setQuery(query);
                const params = new URLSearchParams(history.location.search);
                params.set('search', query || '');
                history.push({ search: params.toString() });
              }}
              onClearSearch={() => setQuery(null)}
              onFilter={newFilter => {
                const updatedFilter = { ...filter, ...newFilter };
                setFilter(updatedFilter);
                const params = new URLSearchParams(history.location.search);
                params.set('filter', JSON.stringify(updatedFilter));
                history.push({ search: params.toString() });
              }}
              onSort={newSort => {
                const updatedSort = { ...sort, ...newSort };
                setSort(updatedSort);
                const params = new URLSearchParams(history.location.search);
                params.set('sort', JSON.stringify(updatedSort));
                history.push({ search: params.toString() });
              }}
              filter={filter}
              sort={sort}
              searchQuery={searchQuery}
              limit={limit}
              onMore={({ offset }) => {
                return fetchMore({
                  variables: {
                    offset,
                    limit,
                  },

                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    const prevProjects = get(prev, 'projectManagement.allProjects');
                    const moreProjects = get(fetchMoreResult, 'projectManagement.allProjects');
                    const newData = {
                      ...prev,
                      projectManagement: {
                        ...prev.projectManagement,
                        allProjects: [...prevProjects, ...moreProjects],
                      },
                    };
                    return newData;
                  },
                });
              }}
            />
          );
        }}
      </Query>
    );
};

export default withRouter(ProjectListContainer);
