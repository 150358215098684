import { memo } from 'react';
import { FileManagerProvider } from './FileManagerContext';
import DocumentFilters from './DocumentFilters';
import DocumentUploadModal from './DocumentUploadModal';
import DocumentList from './DocumentList';
import { useFileManager } from './FileManagerContext';

// Main component content
const FileManagerContent = memo(() => {
  const { setModalOpened } = useFileManager();

  const handleAddDocument = () => {
    setModalOpened(true);
  };

  return (
    <div>
      <div style={{ margin: '10px', fontSize: '15px' }}>
        <DocumentFilters onAddDocument={handleAddDocument} />
        <DocumentUploadModal />
        <DocumentList />
      </div>
    </div>
  );
});

FileManagerContent.displayName = 'FileManagerContent';

// Main component with context provider
const FileManager = ({
  relatedDocumentId,
  relatedModelName,
}: {
  relatedDocumentId: string;
  relatedModelName: string;
}) => {
  return (
    <FileManagerProvider relatedDocumentId={relatedDocumentId} relatedModelName={relatedModelName}>
      <FileManagerContent />
    </FileManagerProvider>
  );
};

export default FileManager;
