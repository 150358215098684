import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

const { RangePicker } = DatePicker;

const StyledDatePicker = styled(RangePicker)`
  .ant-calendar-picker-input {
    box-shadow: 0px 1px 2px 1px #e8e8e8;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin: 0 0.5em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const CustomDateRangePicker = ({
  startDate,
  endDate,
  handleDateChange,
  ranges = {
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    'Last Week': [
      moment().subtract(1, 'week').startOf('isoWeek'),
      moment().subtract(1, 'week').endOf('isoWeek'),
    ],
    'Current Year': [moment().startOf('year'), moment().endOf('year')],
    'Current Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Current Week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
  },
  ...rest
}) => (
  <Wrapper {...rest}>
    <StyledDatePicker
      allowClear={false}
      format="MM/DD/YYYY"
      onChange={handleDateChange}
      ranges={ranges}
      value={[moment(startDate), moment(endDate)]}
      placeholder={['Start Date', 'End Date']}
    />
  </Wrapper>
);

CustomDateRangePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
};

export default CustomDateRangePicker;
