import React from 'react';
import { Button as AntButton } from 'antd';
import { useDisclosure } from '@mantine/hooks';
import { useGlobal } from 'reactn';
import TaskSelectorModal from './TaskSelectorModal';

interface IOption {
  value: string;
  label: string;
  children?: IOption[];
  disabled?: boolean;
}

interface ISelected {
  project: string | undefined;
  category?: string;
  task?: string;
}

interface IProps {
  compact?: boolean;
  options: IOption[];
  defaultSelected?: ISelected;
  onSelect: (selected: ISelected) => void;
  type?: 'primary' | 'default';
}

interface IGlobalState {
  user: any;
}

const TaskSelector = ({ options, compact = false, defaultSelected = { project: undefined, category: undefined, task: undefined }, onSelect, type }: IProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [{ user }] = useGlobal<IGlobalState>();
  const recentProjects = user?.recentProjects || [];

  return (
    <>
      <TaskSelectorModal
        opened={opened}
        onClose={close}
        options={options}
        defaultSelected={defaultSelected}
        onSelect={onSelect}
        recentProjects={recentProjects}
      />
      <AntButton type={type} icon="plus" size={compact ? 'small' : 'large'} onClick={open}>{compact ? null : 'Select Task'}</AntButton>
    </>
  );
};

export default TaskSelector;
