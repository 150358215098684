import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Tabs, { TabPane } from '../../common/components/Tabs';
import Card from '../../common/components/Card';
import ProjectCard from '../../common/components/ProjectCard';
import ProjectDetailsTimesheetList from './ProjectDetailsTimesheetList';
import ProjectDetailsResourceList from './ProjectDetailsResourceList';
import ProjectDetailsDeliverableList from './ProjectDetailsDeliverableList';
import ProjectFinancials from './ProjectFinancials';
import ProjectRoles from './ProjectRoles';
import ProjectExpenses from './ProjectExpenses';
import ProjectSummary from './ProjectSummary';
import ProjectDocuments from './ProjectDocuments';
import TimesheetSnapshotCard from './TimesheetSnapshotCard';
import TimesheetApprovalListContainer from './TimesheetApprovalListContainer';
// import ProjectSchedule from './Scheduler/ProjectSchedule';

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    margin-bottom: 0;
  }
`;

const ProjectDetails = ({ user, goToUrl, project, activeTab, refetchQueries, refetch }) => {
  const [config] = useGlobal('config');
  const tabs = JSON.parse(config['project-details-tabs'] || '[]');
  const history = useHistory();
  if (!project) return null;
  const { location } = history;
  const { pathname } = location;
  let pathPrefix = pathname.split('/').slice(0, 3).join('/');

  if (pathname.includes('finance')) {
    pathPrefix = pathPrefix.replace('project-management', 'finance');
  }

  if (!activeTab) {
    return <Redirect to={`${pathname}/deliverables`} />;
  }

  return (
    <Flex flexDirection="column" pb="6rem">
      <ProjectCard project={project} refetchQueries={refetchQueries} showActions />
      <Box width={1} mt=".5rem">
        <Card border fadeIn withShadow padded={false} floating>
          <StyledTabs
            activeKey={activeTab}
            onChange={activeTab => goToUrl(`${pathPrefix}/${project._id}/${activeTab}`)}
          >
            {tabs.map(tab => {
              switch (tab) {
                case 'summary':
                  return (
                    <TabPane tab="Project Summary" key="summary">
                      <Switch>
                        <Route
                          path={`${pathPrefix}/:projectId/summary`}
                          render={() => <ProjectSummary project={project} />}
                        />
                      </Switch>
                    </TabPane>
                  );
                case 'deliverables':
                  return (
                    <TabPane
                      tab={config['custom-deliverables-tab-name'] || 'Deliverables'}
                      key="deliverables"
                    >
                      <Switch>
                        <Route
                          path={`${pathPrefix}/:projectId/deliverables`}
                          render={() => (
                            <ProjectDetailsDeliverableList
                              projectId={project._id}
                              refetchQueries={refetchQueries}
                            />
                          )}
                        />
                      </Switch>
                    </TabPane>
                  );
                case 'timesheets':
                  return (
                    <TabPane tab="Timesheets" key="timesheets">
                      <Switch>
                        <Route
                          path={`${pathPrefix}/:projectId/timesheets`}
                          render={() => <ProjectDetailsTimesheetList projectId={project._id} />}
                        />
                      </Switch>
                    </TabPane>
                  );
                // case 'schedule':
                //   return (
                //     <TabPane tab="Schedule" key="schedule">
                //       <Switch>
                //         <Route
                //           path={`${pathPrefix}/:projectId/schedule`}
                //           render={() => <ProjectSchedule projectId={project._id} />}
                //         />
                //       </Switch>
                //     </TabPane>
                //   );
                case 'team':
                  return (
                    <TabPane tab="Team" key="team">
                      <Switch>
                        <Route
                          path={`${pathPrefix}/:projectId/team`}
                          render={() => (
                            <ProjectDetailsResourceList
                              roles={project.roles}
                              projectId={project._id}
                            />
                          )}
                        />
                      </Switch>
                    </TabPane>
                  );
                case 'roles':
                  return (
                    <TabPane tab="Roles" key="roles">
                      <Switch>
                        <Route
                          path={`${pathPrefix}/:projectId/roles`}
                          render={() => <ProjectRoles projectId={project._id} />}
                        />
                      </Switch>
                    </TabPane>
                  );
                case 'expenses':
                  return (
                    <TabPane tab={config['custom-expenses-tab-name'] || 'Expenses'} key="expenses">
                      <Switch>
                        <Route
                          path={`${pathPrefix}/:projectId/expenses`}
                          render={() => <ProjectExpenses project={project} />}
                        />
                      </Switch>
                    </TabPane>
                  );
                case 'financials':
                  return (
                    <TabPane tab="Financials" key="financials">
                      <Switch>
                        <Route
                          path={`${pathPrefix}/:projectId/financials`}
                          render={() => <ProjectFinancials project={project} />}
                        />
                      </Switch>
                    </TabPane>
                  );
                case 'export':
                  return (
                    <TabPane tab="Export Hours" key="export">
                      <Switch>
                        <Route
                          path={`${pathPrefix}/:projectId/export`}
                          render={() => <TimesheetSnapshotCard projectId={project._id} />}
                        />
                      </Switch>
                    </TabPane>
                  );
                case 'documents':
                  return (
                    config['pm-documents-tab-enabled'] === 'true' && (
                      <TabPane tab="Documents" key="documents">
                        <Switch>
                          <Route
                            path={`${pathPrefix}/:projectId/documents`}
                            render={() => <ProjectDocuments project={project} />}
                          />
                        </Switch>
                      </TabPane>
                    )
                  );
                default:
                  return null;
              }
            })}
          </StyledTabs>
        </Card>
      </Box>
      <TimesheetApprovalListContainer projectId={project._id} />
    </Flex>
  );
};

export default ProjectDetails;

// {
//   "_id": {
//     "$oid": "67c1e21ae6fa69c3ef931712"
//   },
//   "key": "project-details-tabs",
//   "value": "["summary", "resources", "deliverables", "timesheets", "roles", "expenses", "financials", "export", "documents"]"
// }
